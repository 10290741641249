import { P } from '@piccolohealth/util';
import {
  FormType,
  Gender,
  LabelType,
  Participant,
  Method,
  ParticipantDocumentStatus,
  TemplateType,
  ParticipantStatus,
} from '../graphql';

export const renderParticipantName = (participant: Pick<Participant, 'firstName' | 'lastName'>) => {
  return `${participant.firstName} ${participant.lastName}`;
};

export const renderMethod = (method: Method['__typename']) => {
  switch (method) {
    case 'AbcMethod':
      return 'ABC';
    case 'DurationMethod':
      return 'Duration';
    case 'FrequencyMethod':
      return 'Frequency';
    case 'EpisodicSeverityMethod':
      return 'Episodic Severity';
  }
};

export const renderGender = (gender: Gender) => {
  switch (gender) {
    case Gender.Male: {
      return 'Male';
    }
    case Gender.Female: {
      return 'Female';
    }
    case Gender.Transgender: {
      return 'Transgender';
    }
    case Gender.GenderNeutral: {
      return 'Gender neutral';
    }
    case Gender.NonBinary: {
      return 'Non-binary';
    }
    case Gender.NotSpecified: {
      return 'Not specified';
    }
  }
};

export const renderLabelType = (labelType: LabelType) => {
  switch (labelType) {
    case LabelType.Template: {
      return 'Templates';
    }
    case LabelType.Incident: {
      return 'Incidents';
    }
    case LabelType.Participant: {
      return 'Participants';
    }
    case LabelType.ParticipantDocument: {
      return 'Participant documents';
    }
    case LabelType.ParticipantKeyContact: {
      return 'Participant key contacts';
    }
    case LabelType.ParticipantNote: {
      return 'Participant notes';
    }
    case LabelType.ParticipantUser: {
      return 'Participant team members';
    }
  }
};

export const renderIncidentShortId = (shortId: number) => {
  return `INC-${P.zeroPad(shortId, 3)}`;
};

export const renderTemplateType = (type: TemplateType) => {
  switch (type) {
    case TemplateType.ParticipantDocument: {
      return 'Participant document';
    }
    case TemplateType.ParticipantNote: {
      return 'Participant note';
    }
  }
};

export const renderParticipantStatus = (status: ParticipantStatus) => {
  switch (status) {
    case ParticipantStatus.New: {
      return 'New';
    }
    case ParticipantStatus.Waitlist: {
      return 'Waitlist';
    }
    case ParticipantStatus.Intake: {
      return 'Intake';
    }
    case ParticipantStatus.Active: {
      return 'Active';
    }
    case ParticipantStatus.OnHold: {
      return 'On Hold';
    }
    case ParticipantStatus.Archived: {
      return 'Archived';
    }
  }
};

export const renderParticipantDocumentStatus = (type: ParticipantDocumentStatus) => {
  switch (type) {
    case ParticipantDocumentStatus.Draft: {
      return 'Draft';
    }
    case ParticipantDocumentStatus.Finalized: {
      return 'Finalized';
    }
  }
};

export const renderFormType = (type: FormType) => {
  switch (type) {
    case FormType.Participant: {
      return 'Participant';
    }
  }
};
