import { Text, Spacer, HStack, Stack, Button, Divider, Box, Input } from '@chakra-ui/react';
import { Label, LabelType, ParticipantQrStatus } from '@piccolohealth/pbs-common';
import {
  FloatingPopover,
  MultiSelect,
  MultiSelectOption,
  NumberedBadge,
  OnChangeRequest,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretDown, FaFilter } from 'react-icons/fa';
import { FormItem } from '../../components/forms/FormItem';
import { FormStack } from '../../components/forms/FormStack';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantQRsFilter } from './hooks/useParticipantQRsFilter';
import { participantQRStatusOptions } from './ParticipantQRStatusTag';

interface Props {
  filter: ParticipantQRsFilter;
}

const ParticipantQRNameFilterControl = (props: Props) => {
  const [value, setValue] = React.useState(props.filter.nameFilter);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      props.filter.onNameFilter(value);
    },
    [props.filter],
  );

  React.useEffect(() => {
    setValue(props.filter.nameFilter);
  }, [props.filter.nameFilter]);

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="All QR codes"
      size="sm"
    />
  );
};
const ParticipantQRStatusFilterControl = (props: Props) => {
  const options: MultiSelectOption<ParticipantQrStatus>[] = Object.values(
    participantQRStatusOptions,
  );

  const selectedValues: MultiSelectOption<ParticipantQrStatus>[] = React.useMemo(() => {
    return (props.filter.statusFilter ?? []).flatMap((status) => {
      return options.find((o) => P.isEqual(o.raw, status)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<ParticipantQrStatus>) => {
      props.filter.onStatusFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All statuses"
      optionVariant="tag"
    />
  );
};

const ParticipantQRLabelFilterControl = (props: Props) => {
  const { organization } = useAppContext();
  const { data, isLoading } = useLabelsQuery({
    organizationId: organization.id,
    type: LabelType.ParticipantQr,
  });

  const options: MultiSelectOption<Label>[] = React.useMemo(() => {
    const labels = (data?.organization?.labels as Label[]) ?? [];
    return labels.map((label) => {
      return {
        label: label.name,
        value: label.id,
        raw: label,
        color: label.color,
      };
    });
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Label>[] = React.useMemo(() => {
    return (props.filter.labelFilter ?? []).flatMap((label) => {
      return options.find((o) => P.isEqual(o.raw.id, label)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Label>) => {
      props.filter.onLabelFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All labels"
      optionVariant="tag"
    />
  );
};

export const ParticipantQRFilterControls = (props: Props) => {
  return (
    <FormStack orientation="horizontal">
      <Box maxW="2xs">
        <ParticipantQRNameFilterControl filter={props.filter} />
      </Box>
      <FloatingPopover
        isPortal
        render={() => (
          <Stack bg="white" spacing={0} w="xs" shadow="popover" rounded="md" overflow="hidden">
            <HStack py={2} px={4} borderBottomWidth="1px">
              <Text fontWeight="semibold">Filters</Text>
              <Spacer />
              <NumberedBadge count={props.filter.activeFilterCount} />
            </HStack>
            <FormStack py={4} px={4} orientation="vertical">
              <FormItem label="Status">
                <ParticipantQRStatusFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label="Labels">
                <ParticipantQRLabelFilterControl filter={props.filter} />
              </FormItem>

              <Divider />

              <Button
                w="fit-content"
                size="sm"
                variant="outline"
                isDisabled={props.filter.activeFilterCount === 0}
                onClick={props.filter.reset}
              >
                Reset
              </Button>
            </FormStack>
          </Stack>
        )}
      >
        <Button
          size="sm"
          variant="outline"
          lineHeight="unset"
          leftIcon={<FaFilter />}
          rightIcon={<FaCaretDown />}
        >
          <HStack>
            <Text>Filter</Text>
            <Box w="28px">
              <NumberedBadge count={props.filter.activeFilterCount} />
            </Box>
          </HStack>
        </Button>
      </FloatingPopover>
    </FormStack>
  );
};
