import { Text } from '@chakra-ui/react';
import React from 'react';
import { DeleteConfirmModal } from '../../../components/generic/DeleteConfirmModal';
import { createModal } from '../../../components/generic/Modal';
import { useDeleteParticipantRestrictedPracticeMutation } from '../../../graphql/hooks/useDeleteParticipantRestrictedPracticeMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  participantId: string;
  participantRestrictedPracticeId: string;
}

export const ParticipantRestrictedPracticeDeleteModal = createModal<Props>((props) => {
  const { participantId, participantRestrictedPracticeId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantRestrictedPracticeMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        restrictedPracticeId: participantRestrictedPracticeId,
      })
      .then(async () => {
        successToast('Restricted practice deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting restricted practice: ${err.message}`);
      });
  }, [
    mutation,
    organization.id,
    participantId,
    participantRestrictedPracticeId,
    successToast,
    errorToast,
  ]);

  return (
    <DeleteConfirmModal
      modal={modal}
      heading="Do you want to delete this restricted practice?"
      description={
        <Text>
          Deleting this restricted practice <strong>will remove its associated data.</strong>
        </Text>
      }
      requireConfirm
      onDelete={onDelete}
      isLoading={mutation.isLoading}
    />
  );
});
