import { RestrictedPracticeStatus, RestrictedPracticeType } from '@piccolohealth/pbs-common';
import React from 'react';

const DEFAULT_DESCRIPTION_FILTER: string | undefined = '';
const DEFAULT_TYPE_FILTER: RestrictedPracticeType[] = [];
const DEFAULT_STATUS_FILTER: RestrictedPracticeStatus[] = [];

export interface ParticipantRestrictedPracticesFilter {
  descriptionFilter: string;
  typeFilter: RestrictedPracticeType[];
  statusFilter: RestrictedPracticeStatus[];
  activeFilterCount: number;
  onDescriptionFilter: (description: string) => void;
  onTypeFilter: (types: RestrictedPracticeType[]) => void;
  onStatusFilter: (statuses: RestrictedPracticeStatus[]) => void;
  reset: () => void;
}

export const useParticipantRestrictedPracticesFilter = (): ParticipantRestrictedPracticesFilter => {
  const [params, setParams] = React.useState({
    description: DEFAULT_DESCRIPTION_FILTER,
    type: DEFAULT_TYPE_FILTER,
    status: DEFAULT_STATUS_FILTER,
  });

  const onDescriptionFilter = (value: string) =>
    setParams((prev) => ({
      ...prev,
      description: value,
    }));

  const onTypeFilter = (value: RestrictedPracticeType[]) =>
    setParams((prev) => ({
      ...prev,
      type: value,
    }));

  const onStatusFilter = (value: RestrictedPracticeStatus[]) =>
    setParams((prev) => ({
      ...prev,
      status: value,
    }));

  const reset = () =>
    setParams({
      description: DEFAULT_DESCRIPTION_FILTER,
      type: DEFAULT_TYPE_FILTER,
      status: DEFAULT_STATUS_FILTER,
    });

  const activeFilterCount = [params.description.length > 0, params.type.length > 0].filter(
    Boolean,
  ).length;

  return {
    descriptionFilter: params.description,
    typeFilter: params.type,
    statusFilter: params.status,
    activeFilterCount,
    onDescriptionFilter,
    onTypeFilter,
    onStatusFilter,
    reset,
  };
};
