import {
  Button,
  ButtonGroup,
  Divider,
  Heading,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { renderTemplateType, TemplateType } from '@piccolohealth/pbs-common';
import { Empty, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useTemplatesFilterQuery } from '../../graphql/hooks/useTemplatesQuery';
import { usePermission } from '../../hooks/usePermission';
import { useTemplatesFilter } from './hooks/useTemplatesFilter';
import { TemplateCard } from './TemplateCard';
import { TemplateCreateModal } from './TemplateCreateModal';
import { TemplatesFilterControls } from './TemplatesFilterControls';

const DOCUMENT_TYPES = [
  {
    type: TemplateType.ParticipantDocument,
    description: 'Templates that can be used when creating documents under a participant',
  },
  {
    type: TemplateType.ParticipantNote,
    description: 'Templates that can be used when creating notes under a participant',
  },
];

export const Templates = () => {
  const filter = useTemplatesFilter();

  const createTemplatePermission = usePermission('create', 'template');

  const { isLoading, error, templates } = useTemplatesFilterQuery(filter);

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error error={error} />;
    }

    if (P.isEmpty(templates)) {
      return <Empty title="No templates found" />;
    }

    const sections = DOCUMENT_TYPES.map(({ type, description }) => {
      const filteredTemplates = templates.filter((template) => template.type === type);

      if (P.isEmpty(filteredTemplates)) {
        return null;
      }

      return (
        <Stack key={type} spacing={4}>
          <Stack spacing={1}>
            <Heading size="md">{renderTemplateType(type)}</Heading>
            <Text fontSize="sm" color="secondary">
              {description}
            </Text>
          </Stack>
          <Wrap spacing={4} shouldWrapChildren>
            {filteredTemplates.map((template) => (
              <TemplateCard key={template.id} template={template} />
            ))}
          </Wrap>
        </Stack>
      );
    });

    return (
      <Stack spacing={8} divider={<Divider />}>
        {sections}
      </Stack>
    );
  });

  return (
    <ContentLayout
      headerContent={
        <HStack align="start">
          <Heading size="lg">Templates</Heading>
          <Spacer />
          <ButtonGroup>
            <Button
              isDisabled={!createTemplatePermission}
              leftIcon={<Icon as={FaPlus} />}
              colorScheme="purple"
              size="sm"
              onClick={() => showModal(TemplateCreateModal)}
            >
              Add a template
            </Button>
          </ButtonGroup>
        </HStack>
      }
    >
      <Stack spacing={8} px={6}>
        <TemplatesFilterControls filter={filter} />
        {content}
      </Stack>
    </ContentLayout>
  );
};
