import { Button, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Method,
  ParticipantBehaviour,
  ParticipantBehaviourRecording,
  UpdateParticipantBehaviourRecordingRequest,
} from '@piccolohealth/pbs-common';
import { FloatingPopover, ScrollAreaAutosize } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaEdit } from 'react-icons/fa';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { useUpdateParticipantBehaviourRecordingMutation } from '../../../graphql/hooks/useUpdateParticipantBehaviourRecordingMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { dateTimeSchema } from '../../../utils/zod';
import { ParticipantBehaviourRecordingForm } from './ParticipantBehaviourRecordingForm';

type FormValues = UpdateParticipantBehaviourRecordingRequest & {
  participantBehaviourId: string;
  method: Method['__typename'];
};

const payloadSchema = z.object({
  frequency: z
    .object({
      value: z.number(),
    })
    .optional(),
  duration: z
    .object({
      value: z.number(),
    })
    .optional(),
  abc: z
    .object({
      antecedent: z.string(),
      behaviour: z.string(),
      consequence: z.string(),
    })
    .optional(),
  episodicSeverity: z
    .object({
      level: z.number(),
    })
    .optional(),
});

const schema = z.object({
  participantBehaviourId: z.string({}),
  timestamp: dateTimeSchema,
  method: z.string({
    required_error: 'A recording method is required',
  }),
  payload: payloadSchema,
});

interface ParticipantBehaviourRecordingEditContentProps {
  participantId: string;
  participantBehaviours: ParticipantBehaviour[];
  recording: ParticipantBehaviourRecording;
  onClose: () => void;
}

const ParticipantBehaviourRecordingEditContent = (
  props: ParticipantBehaviourRecordingEditContentProps,
) => {
  const { participantId, participantBehaviours, recording, onClose } = props;

  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateParticipantBehaviourRecordingMutation();

  const initialValues: Partial<FormValues> = {
    participantBehaviourId: recording.participantBehaviourId,
    comment: recording.comment,
    timestamp: recording.timestamp,
    method: recording.method.__typename,
    payload: P.run(() => {
      switch (recording.__typename) {
        case 'ParticipantBehaviourRecordingAbc':
          return {
            abc: {
              antecedent: recording.antecedent,
              behaviour: recording.behaviour,
              consequence: recording.consequence,
            },
          };
        case 'ParticipantBehaviourRecordingFrequency':
          return { frequency: { value: recording.value } };
        case 'ParticipantBehaviourRecordingDuration':
          return { duration: { value: recording.value } };
        case 'ParticipantBehaviourRecordingEpisodicSeverity':
          return { episodicSeverity: { level: recording.value } };
        default:
          return {};
      }
    }),
  };

  const methods = useForm<FormValues>({
    defaultValues: initialValues,
    resolver: zodResolver(schema),
  });

  const { reset } = methods;

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const payload = P.run(() => {
        switch (values.method) {
          case 'AbcMethod':
            return { abc: values.payload?.abc };
          case 'FrequencyMethod':
            return { frequency: values.payload?.frequency };
          case 'DurationMethod':
            return { duration: values.payload?.duration };
          case 'EpisodicSeverityMethod':
            return { episodicSeverity: values.payload?.episodicSeverity };
          default:
            return {};
        }
      });

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantId: participantId,
          participantBehaviourId: values.participantBehaviourId,
          recordingId: recording.id,
          request: {
            payload,
            timestamp: values.timestamp,
          },
        })
        .then(async () => {
          successToast('Participant behaviour recording updated successfully');
          onClose();
          reset();
        })
        .catch((err) => {
          errorToast(`Error updating participant behaviour recording: ${err.message}`);
        });
    },
    [
      mutation,
      organization.id,
      participantId,
      recording.id,
      successToast,
      onClose,
      reset,
      errorToast,
    ],
  );

  return (
    <Stack minH="xs" w="xl" bg="white" spacing={0} shadow="popover" rounded="xl">
      <HStack px={6} py={3} borderBottomWidth="1px">
        <Text fontSize="md" fontWeight="semibold">
          Edit recording
        </Text>
      </HStack>

      <FormProvider {...methods}>
        <ScrollAreaAutosize maxH="3xl" h="full">
          <Stack as="form" px={6} py={4} spacing={8} onSubmit={methods.handleSubmit(onSubmit)}>
            <ParticipantBehaviourRecordingForm participantBehaviours={participantBehaviours} />
            <HookedSubmitButton isDisabled={false} leftIcon={<FaEdit />}>
              Edit data
            </HookedSubmitButton>
          </Stack>
        </ScrollAreaAutosize>
      </FormProvider>
    </Stack>
  );
};

interface Props {
  participantId: string;
  participantBehaviours: ParticipantBehaviour[];
  recording: ParticipantBehaviourRecording;
}

export const ParticipantBehaviourRecordingEditButton = (props: Props) => {
  const { participantId, participantBehaviours, recording } = props;

  const disclosure = useDisclosure();

  return (
    <FloatingPopover
      placement="bottom-start"
      open={disclosure.isOpen}
      setOpen={(isOpen) => (isOpen ? disclosure.onOpen() : disclosure.onClose())}
      isPortal
      render={() => (
        <ParticipantBehaviourRecordingEditContent
          participantId={participantId}
          participantBehaviours={participantBehaviours}
          recording={recording}
          onClose={disclosure.onClose}
        />
      )}
    >
      <Button size="sm" variant="link">
        Edit
      </Button>
    </FloatingPopover>
  );
};
