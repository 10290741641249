import gql from 'graphql-tag';

export const DeleteParticipantRestrictedPracticeLabel = gql`
  mutation DeleteParticipantRestrictedPracticeLabel(
    $organizationId: ID!
    $participantId: ID!
    $restrictedPracticeId: ID!
    $labelId: ID!
  ) {
    deleteParticipantRestrictedPracticeLabel(
      organizationId: $organizationId
      participantId: $participantId
      restrictedPracticeId: $restrictedPracticeId
      labelId: $labelId
    )
  }
`;
