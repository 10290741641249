import { P } from '@piccolohealth/util';
import {
  Method,
  ParticipantBehaviour,
  ParticipantBehaviourStatus,
  ParticipantQrStatus,
  ParticipantRecordingInfo,
  ParticipantStatus,
} from '../graphql';
import {
  PARTICIPANT_BEHAVIOUR_DISABLED_STATUSES,
  PARTICIPANT_DISABLED_STATUSES,
  PARTICIPANT_QR_DISABLED_STATUSES,
} from './constants';

export const isParticipantDisabled = (status: ParticipantStatus): boolean => {
  return PARTICIPANT_DISABLED_STATUSES.includes(status);
};

export const isBehaviourDisabled = (status: ParticipantBehaviourStatus): boolean => {
  return PARTICIPANT_BEHAVIOUR_DISABLED_STATUSES.includes(status);
};

export const isQrDisabled = (status: ParticipantQrStatus): boolean => {
  return PARTICIPANT_QR_DISABLED_STATUSES.includes(status);
};

export const isRecordingDisabled = (participant: ParticipantRecordingInfo): boolean => {
  return (
    isParticipantDisabled(participant.status) ||
    (participant.qr?.status ? isQrDisabled(participant.qr.status) : false)
  );
};

export const getEnabledMethods = (participantBehaviour: ParticipantBehaviour): Method[] => {
  return Object.values(participantBehaviour.methods).filter(
    (method) => !P.isString(method) && method.enabled,
  ) as Method[];
};
