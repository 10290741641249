import {
  Avatar,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  forwardRef,
  HStack,
  IconButton,
  Show,
  Spacer,
  Stack,
  StackProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import React from 'react';
import { FaBars, FaMobileAlt } from 'react-icons/fa';
import { TbLayoutSidebarRightCollapse } from 'react-icons/tb';
import { NavLink, To, useLocation } from 'react-router-dom';
import { OrganizationChooserButton } from '../../features/organization/OrganizationChooser';
import { useAppContext } from '../../hooks/useAppContext';
import { PiccoloIcon } from '../generic/PiccoloIcon';

export const SidebarDivider = (props: BoxProps) => (
  <Box px={4} {...props}>
    <Divider />
  </Box>
);

interface SidebarSectionProps extends StackProps {
  title?: string;
}

export const SidebarSection = (props: React.PropsWithChildren<SidebarSectionProps>) => {
  const { title, children, ...rest } = props;

  return (
    <Stack px={4} py={4} {...rest}>
      {title && (
        <Text fontWeight="bold" fontSize="md">
          {title}
        </Text>
      )}
      <Stack spacing={2}>{children}</Stack>
    </Stack>
  );
};

export interface SidebarButtonProps extends ButtonProps {
  isShrunk?: boolean;
}

export const SidebarButton = forwardRef<SidebarButtonProps, 'button'>((props, ref) => {
  const { isActive, isShrunk, children, ...rest } = props;

  if (isShrunk) {
    return (
      <IconButton
        ref={ref}
        variant="ghost"
        icon={rest.leftIcon}
        aria-label={'sidebar-button'}
        color="gray.600"
        isActive={isActive}
        _active={{
          color: 'purple.500',
          bg: 'gray.100',
        }}
        {...rest}
        leftIcon={null}
      />
    );
  }

  return (
    <Button
      ref={ref}
      variant="ghost"
      justifyContent="start"
      w="full"
      color="gray.600"
      isActive={isActive}
      _active={{
        color: 'purple.500',
        bg: 'gray.100',
      }}
      {...rest}
    >
      {children}
    </Button>
  );
});

export interface SidebarButtonLinkProps extends SidebarButtonProps {
  to: To;
}

export const SidebarButtonLink = (props: React.PropsWithChildren<SidebarButtonLinkProps>) => {
  const { to, ...rest } = props;

  return (
    <NavLink to={props.to}>
      {({ isActive }) => <SidebarButton isActive={isActive} {...rest} />}
    </NavLink>
  );
};

interface SidebarHeaderProps {
  isShrunk: boolean;
  onShrink: () => void;
}

const SidebarHeader = (props: SidebarHeaderProps) => {
  const { isShrunk, onShrink } = props;

  if (isShrunk) {
    return (
      <HStack justify="center" align="center" py={4}>
        <IconButton
          size="md"
          variant="ghost"
          icon={<TbLayoutSidebarRightCollapse size="20px" />}
          aria-label={'sidebar-button'}
          color="gray.600"
          onClick={onShrink}
        />
      </HStack>
    );
  }

  return <OrganizationChooserButton onShrink={onShrink} />;
};

interface SidebarContentProps extends BoxProps {
  isShrunk: boolean;
  onShrink: () => void;
}

const SidebarContent = (props: SidebarContentProps) => {
  const { isShrunk, onShrink, children, ...rest } = props;

  return (
    <Stack
      borderColor="gray.200"
      borderRightWidth="1px"
      h="full"
      flexShrink={0}
      spacing={0}
      w={{ base: 'full', md: isShrunk ? 'auto' : '220px' }}
      {...rest}
    >
      <SidebarHeader isShrunk={isShrunk} onShrink={onShrink} />
      {children}
    </Stack>
  );
};

interface MobileNavProps extends StackProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileNavProps) => {
  const { organization } = useAppContext();

  return (
    <HStack
      flexShrink={0}
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      py={4}
      borderBottomWidth="1px"
      {...rest}
    >
      {organization.logo ? (
        <Avatar
          src={organization.logo ?? undefined}
          w="42px"
          h="42px"
          p="2px"
          color="purple.600"
          borderRadius="6px"
          fontWeight="bold"
          name={organization.name}
          bg="transparent"
          ignoreFallback
        />
      ) : (
        <PiccoloIcon boxSize="6" color="purple.500" />
      )}
      <Text pt={1} fontSize={16} fontWeight="bold">
        {organization.name}
      </Text>
      <Spacer />

      <NavLink to="/recording">
        <Button colorScheme="purple" leftIcon={<FaMobileAlt />}>
          Mobile data collection
        </Button>
      </NavLink>
      <IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FaBars />} />
    </HStack>
  );
};

interface SidebarLayoutProps {
  width?: string;
  sidebarContent: (props: { isShrunk: boolean }) => React.ReactNode;
  mainContent: React.ReactNode;
}

export const SidebarLayout = (props: SidebarLayoutProps) => {
  const { sidebarContent, mainContent } = props;

  const desktopSidebar = useDisclosure();
  const mobileSidebar = useDisclosure();
  const location = useLocation();
  const { onClose } = mobileSidebar;

  React.useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <Flex h="full" flexDir="column">
      <Show below="md">
        <MobileNav onOpen={mobileSidebar.onOpen} />

        <Drawer
          onClose={mobileSidebar.onClose}
          isOpen={mobileSidebar.isOpen}
          placement="left"
          returnFocusOnClose={true}
          onOverlayClick={mobileSidebar.onClose}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarContent display="flex" onShrink={mobileSidebar.onToggle} isShrunk={false}>
              {sidebarContent({ isShrunk: false })}
            </SidebarContent>
          </DrawerContent>
        </Drawer>
      </Show>
      <Flex h="full" minH={0}>
        <Show above="md">
          <SidebarContent
            onShrink={desktopSidebar.onToggle}
            isShrunk={desktopSidebar.isOpen}
            display="flex"
          >
            {sidebarContent({ isShrunk: desktopSidebar.isOpen })}
          </SidebarContent>
        </Show>
        <Box h="full" w="full" overflowY="auto" bg="white">
          {mainContent}
        </Box>
      </Flex>
    </Flex>
  );
};
