import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateParticipantBehaviourRecording } from '../mutations/updateParticipantBehaviourRecording.mutation';
import {
  UpdateParticipantBehaviourRecordingMutation,
  UpdateParticipantBehaviourRecordingMutationVariables,
} from '../types';

export const useUpdateParticipantBehaviourRecordingMutation = (
  options?: MutationOptions<
    UpdateParticipantBehaviourRecordingMutation,
    UpdateParticipantBehaviourRecordingMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateParticipantBehaviourRecording), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
