import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreateParticipantRestrictedPracticeRequest,
  RestrictedPracticeType,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useCreateParticipantRestrictedPracticeMutation } from '../../../graphql/hooks/useCreateParticipantRestrictedPracticeMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { dateTimeSchema } from '../../../utils/zod';
import { ParticipantRestrictedPracticeForm } from './ParticipantRestrictedPracticeForm';
import { DateTime } from '@piccolohealth/util';

type FormValues = CreateParticipantRestrictedPracticeRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  description: z.string().nonempty('Please enter a description'),
  type: z.nativeEnum(RestrictedPracticeType),
  identifiedAt: dateTimeSchema,
  startedAt: dateTimeSchema.optional().nullable(),
  ceasedAt: dateTimeSchema.optional().nullable(),
  labelIds: z.array(z.string()),
});

interface Props {
  participantId: string;
}

export const ParticipantRestrictedPracticeCreateModal = createModal<Props>((props) => {
  const { modal } = props;
  const { hide, visible } = modal;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useCreateParticipantRestrictedPracticeMutation();

  const methods = useForm<FormValues>({
    defaultValues: {
      description: '',
      identifiedAt: DateTime.now(),
      startedAt: null,
      ceasedAt: null,
      type: RestrictedPracticeType.ChemicalRestraint,
      labelIds: [],
    },
    resolver: zodResolver(schema),
    mode: 'all',
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantId: props.participantId,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Participant restricted practice created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating participant restricted practice: ${err.message}`);
        });
    },
    [errorToast, hide, mutation, organization.id, props.participantId, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Create a participant restricted practice</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <ParticipantRestrictedPracticeForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Create restricted practice</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
