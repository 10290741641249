import { useAuth0 } from '@auth0/auth0-react';
import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';

export const Logout = () => {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated) {
      logout();
    }
  }, [isAuthenticated, logout]);

  const login = React.useCallback(async () => {
    await loginWithRedirect({
      appState: { targetUrl: '/' },
    });
  }, [loginWithRedirect]);

  return (
    <ChakraV3.Result
      status="success"
      title="You are now logged out of Piccolo Health"
      extra={<ChakraV3.Button onClick={() => login()}>Login</ChakraV3.Button>}
    />
  );
};
