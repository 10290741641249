import React from 'react';
import { QRCode, QRCodeProps } from '../../components/generic/QRCode';

interface Props extends Omit<QRCodeProps, 'value'> {
  qrId: string;
}

export const ParticipantQRCode = (props: Props) => {
  const { qrId, ...rest } = props;
  const value = `${window.origin}/recording/qr/${qrId}`;

  return <QRCode value={value} h={24} w={24} {...rest} />;
};
