import gql from 'graphql-tag';

export const CreateParticipantRestrictedPracticeLabel = gql`
  mutation CreateParticipantRestrictedPracticeLabel(
    $organizationId: ID!
    $participantId: ID!
    $restrictedPracticeId: ID!
    $labelId: ID!
  ) {
    createParticipantRestrictedPracticeLabel(
      organizationId: $organizationId
      participantId: $participantId
      restrictedPracticeId: $restrictedPracticeId
      labelId: $labelId
    ) {
      id
    }
  }
`;
