import { LabelType, ParticipantBehaviour } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormStack } from '../../components/forms/FormStack';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedLabelChooser } from '../../components/forms/HookedLabelChooser';
import { HookedSelect } from '../../components/forms/HookedSelect';
import { ParticipantQRBehavioursControl } from './ParticipantQRBehavioursControl';
import { participantQRStatusOptions } from './ParticipantQRStatusTag';

interface Props {
  participantBehaviours: ParticipantBehaviour[];
}

export const ParticipantQRForm = (props: Props) => {
  const { participantBehaviours } = props;

  return (
    <FormStack>
      <HookedFormItem name="name" label="Name">
        <HookedInput name="name" size="sm" placeholder="Enter a name" noNulls />
      </HookedFormItem>

      <HookedFormItem name="status" label="Status">
        <HookedSelect
          name="status"
          size="sm"
          placeholder="Choose a status"
          optionVariant="tag"
          options={Object.values(participantQRStatusOptions)}
        />
      </HookedFormItem>

      <HookedFormItem name="behaviours" label="Behaviours">
        <ParticipantQRBehavioursControl participantBehaviours={participantBehaviours} />
      </HookedFormItem>

      <HookedFormItem name="labelIds" label="Labels">
        <HookedLabelChooser name="labelIds" type={LabelType.ParticipantQr} />
      </HookedFormItem>
    </FormStack>
  );
};
