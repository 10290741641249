import TiptapTableHeader from '@tiptap/extension-table-header';

export const TableHeader = TiptapTableHeader.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-background-color'),
        renderHTML: ({ backgroundColor }) => {
          const attrs: { 'data-background-color': any; style?: string } = {
            'data-background-color': backgroundColor,
          };
          if (backgroundColor) {
            attrs.style = `background-color: ${backgroundColor}`;
          }
          return attrs;
        },
      },
    };
  },
});
