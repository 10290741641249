import { useAuth0 } from '@auth0/auth0-react';
import { renderPiccoloError } from '@piccolohealth/pbs-common';
import axios from 'axios';
import React from 'react';
import { asPiccoloError } from '../../../utils/errors';

export const useImageUpload = (options: { imageUploadUrl: string }) => {
  const { imageUploadUrl } = options;
  const { getAccessTokenSilently } = useAuth0();

  const onImageUpload = React.useCallback(
    async (file: File) => {
      const accessToken = await getAccessTokenSilently();

      const formData = new FormData();
      formData.append('files', file);

      const response = await axios(imageUploadUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
      }).catch((error) => {
        const piccoloError = asPiccoloError(error);
        piccoloError.message = renderPiccoloError(piccoloError).message;
        throw piccoloError;
      });

      return response.data.url;
    },
    [getAccessTokenSilently, imageUploadUrl],
  );

  return onImageUpload;
};
