import { HStack, Stack, Text } from '@chakra-ui/react';
import { User } from '@piccolohealth/pbs-common';
import { ChakraV3, ScrollAreaAutosize, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { useParticipantAuditTrailEntriesQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantTimelineItem } from './ParticipantTimelineItem';

interface Props {
  participantId: string;
}

export const ParticipantTimelineContents = (props: Props) => {
  const { participantId } = props;
  const { organization } = useAppContext();

  const { isLoading, error, data } = useParticipantAuditTrailEntriesQuery({
    organizationId: organization.id,
    participantId,
    request: {
      pagination: {
        limit: 1000,
      },
    },
  });

  const entries = data?.organization?.participant?.entries.results ?? [];

  const contents = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error error={error} />;
    }

    if (P.isEmpty(entries)) {
      return <ChakraV3.Result h="full" status="info" title="No history found for participant" />;
    }

    return entries.map((entry) => {
      return (
        <ParticipantTimelineItem
          key={entry.id}
          timestamp={entry.timestamp}
          message={entry.message}
          user={entry.user as User}
          payload={entry.payload}
        />
      );
    });
  });

  return (
    <Stack w="lg" bg="white" spacing={0} shadow="popover" rounded="xl">
      <HStack px={6} py={3} borderBottomWidth="1px">
        <Text fontSize="xl" fontWeight="bold">
          History
        </Text>
      </HStack>
      <ScrollAreaAutosize minH="sm" maxH="2xl" pb={2}>
        {contents}
      </ScrollAreaAutosize>
    </Stack>
  );
};
