import type { ComponentStyleConfig } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { Accordion } from './components/accordion';
import { Alert } from './components/alert';
import { Badge } from './components/badge';
import { Button } from './components/button';
import { Card } from './components/card';
import { Checkbox } from './components/checkbox';
import { Input } from './components/input';
import { Menu } from './components/menu';
import { Modal } from './components/modal';
import { Popover } from './components/popover';
import { Select } from './components/select';
import { Table } from './components/table';
import { Tabs } from './components/tabs';
import { Text } from './components/text';
import { Textarea } from './components/textarea';
import { Tooltip } from './components/tooltip';
import { tiptapCss } from './tiptap';

const components: Record<string, ComponentStyleConfig> = {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Input,
  Menu,
  Modal,
  Popover,
  Select,
  Table,
  Tabs,
  Text,
  Textarea,
  Tooltip,
};

export const theme = extendTheme({
  fonts: {
    heading: `Inter, sans-serif`,
    body: `Inter, sans-serif`,
  },
  breakpoints: {
    '3xl': '1800px',
  },
  styles: {
    global: () => ({
      'html, body, #app': {
        fontSize: '14px',
        h: 'full',
        m: 0,
        p: 0,
        scrollBehavior: 'smooth',
      },

      '*:focus-visible': {
        outline: 'none',
      },

      '.lagRadar': {
        position: 'fixed',
        bottom: '0.5rem',
        right: '0.5rem',
        zIndex: 10000,
      },

      em: {
        fontStyle: 'revert',
      },

      strong: {
        fontStyle: 'revert',
      },

      'ol, ul': {
        listStyle: 'revert',
      },

      ...tiptapCss,
    }),
  },
  colors: {
    danger: '#E53E3E',
    warning: '#DD6B20',
    success: '#38A169',
    info: '#9F7AEA',
    extraDarkPurple: '#00152A',
    darkRed: '#f5222d',
    darkOrange: '#fa8c16',
    darkPurple: '#b37feb',
    lightBlue: '#bae7ff',
    lightGreen: '#b7eb8f',
    lightGrey: '#e8e8e8',
    lightOrange: '#ffe7ba',
    lightPurple: '#efdbff',
    mediumOrange: '#ffc069',
    secondary: '#718096',
    iosBlue: '#007AFF',
  },
  shadows: {
    popover: `rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px`,
  },
  layerStyles: {
    fade: {
      maskImage: 'linear-gradient(black 90%, transparent 100%)',
    },
    bordered: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      rounded: 'xl',
    },
    inline: {
      w: 'full',
      bg: 'transparent',
      _hover: {
        bg: 'inherit',
      },
      _focus: {
        bg: 'inherit',
      },
      _focusWithin: {
        bg: 'inherit',
      },
      _focusVisible: {
        bg: 'inherit',
      },
      _disabled: {
        cursor: 'not-allowed',
        opacity: 1,
      },
    },
    inlineFocus: {
      _focusWithin: {
        // #EDF2F7 = gray.100
        bg: '#EDF2F7',
        rounded: 'sm',
        boxShadow: '0 0 0 1px #EDF2F7, 0 0 0 3px #EDF2F7;',
      },
    },
  },
  components,
});
