import { Button, HStack, Image, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { ParticipantWidgetLayoutItem, Widget } from '@piccolohealth/pbs-common';
import { FloatingPopover } from '@piccolohealth/ui';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import Chart from '../../../../assets/chart.png';
import Table from '../../../../assets/table.png';
import { FormStack } from '../../../../components/forms/FormStack';
import { showModal } from '../../../../components/generic/Modal';
import { TableWidgetAddModal } from '../table/TableWidgetAddModal';
import { TimeSeriesWidgetAddModal } from '../timeseries/TimeSeriesWidgetAddModal';

interface ParticipantWidgetLayoutItemAddContentProps {
  participantId: string;
  onAdd: (item: ParticipantWidgetLayoutItem) => Promise<void>;
  onClose: () => void;
}

const ParticipantWidgetLayoutItemAddContent = (
  props: ParticipantWidgetLayoutItemAddContentProps,
) => {
  const { participantId, onClose } = props;

  const onAdd = async (widget: Widget) => {
    await props.onAdd({
      id: uuid(),
      widget,
      x: 0,
      y: 0,
      w: 1,
      h: 1,
    });
  };

  const items = [
    {
      name: 'Chart',
      description: 'Display data over time. Useful for frequency, or other time-series data.',
      icon: Chart,
      onClick: () => {
        showModal(TimeSeriesWidgetAddModal, {
          participantId,
          onAdd,
        });
        onClose();
      },
    },
    {
      name: 'Table',
      description: 'Display data in a table. Useful for ABC data, or other tabular data.',
      icon: Table,
      onClick: () => {
        showModal(TableWidgetAddModal, {
          participantId,
          onAdd,
        });
        onClose();
      },
    },
  ];

  return (
    <Stack bg="white" spacing={0} shadow="popover" rounded="md" overflow="hidden" color="initial">
      <HStack px={6} py={3} borderBottomWidth="1px">
        <Text fontSize="md" fontWeight="semibold">
          Add widget
        </Text>
      </HStack>
      <FormStack py={4} px={4} orientation="vertical" textAlign="left">
        <Stack spacing={4}>
          {items.map((item) => (
            <Button
              key={item.name}
              onClick={item.onClick}
              w="sm"
              h="fit-content"
              rounded="lg"
              py={3}
              px={3}
              variant="ghost"
              borderColor="gray.200"
              borderWidth="1px"
            >
              <HStack fontSize="md" w="full" align="center" justify="start" spacing={4}>
                <Image src={item.icon} w="56px" bg="white" p={2} rounded="lg" />
                <Stack w="full" align="start" spacing={0}>
                  <Text fontWeight="bold">{item.name}</Text>
                  <Text
                    fontSize="sm"
                    fontWeight="normal"
                    textAlign="left"
                    whiteSpace="break-spaces"
                    color="gray.600"
                  >
                    {item.description}
                  </Text>
                </Stack>
              </HStack>
            </Button>
          ))}
        </Stack>
      </FormStack>
    </Stack>
  );
};

interface Props {
  participantId: string;
  onAdd: (item: ParticipantWidgetLayoutItem) => Promise<void>;
  isDisabled?: boolean;
}

export const ParticipantWidgetLayoutItemAddControl = (props: Props) => {
  const { participantId, onAdd, isDisabled } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FloatingPopover
      open={isOpen}
      setOpen={(v) => (v ? onOpen() : onClose())}
      render={() => (
        <ParticipantWidgetLayoutItemAddContent
          participantId={participantId}
          onAdd={onAdd}
          onClose={onClose}
        />
      )}
    >
      <Button colorScheme="purple" leftIcon={<FaPlus />} size="sm" isDisabled={isDisabled}>
        Add widget
      </Button>
    </FloatingPopover>
  );
};
