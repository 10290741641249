import { Button, Divider, Input, Spacer, Stack } from '@chakra-ui/react';
import { TableWidget } from '@piccolohealth/pbs-common';
import { ScrollArea } from '@piccolohealth/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormItem } from '../../../../components/forms/FormItem';
import { FormSection } from '../../../../components/forms/FormSection';
import { FormStack } from '../../../../components/forms/FormStack';
import { UseTableReturn } from '../../hooks/useTable';
import { TableSeriesList } from './TableSeriesList';

interface Props {
  table: UseTableReturn;
  onSubmit: (value: TableWidget) => void;
}

export const TableWidgetModalSidebar = (props: Props) => {
  const { table, onSubmit } = props;

  return (
    <Stack w="390px" h="full" spacing={4} flexShrink={0}>
      <ScrollArea h="full">
        <FormStack p={4}>
          <FormSection heading="Basic details" description="Set the basic details of the table">
            <FormItem label="Table title">
              <Input
                value={table.widget.title}
                onChange={(e) => table.onSetTitle(e.target.value)}
                size="sm"
                placeholder="Enter table title"
              />
            </FormItem>
          </FormSection>

          <Divider />

          <FormSection heading="Behaviours" description="Select the behaviours you want to view">
            <TableSeriesList table={table} />
          </FormSection>

          <Divider />

          <Spacer />
        </FormStack>
      </ScrollArea>
      <Stack px={4} pb={4}>
        <Button
          leftIcon={<FaPlus />}
          size="sm"
          colorScheme="purple"
          onClick={() => onSubmit(table.widget)}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
