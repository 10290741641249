import { P } from '@piccolohealth/util';
import { mergeAttributes, Node, nodeInputRule } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ImageNodeView } from './ImageNodeView';
/**
 * Extension based on:
 * - Tiptap Image extension (https://tiptap.dev/api/nodes/image)
 */

export interface ImageOptions {
  allowBase64: boolean;
  onImageUpload: (file: File) => Promise<string>;
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      setImage: (options: { src: string; alt?: string; title?: string }) => ReturnType;
    };
  }
}

// Regex to detect markdown based images.
export const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const Image = Node.create<ImageOptions>({
  name: 'image',

  addOptions() {
    return {
      allowBase64: false,
      onImageUpload: () => {
        return Promise.reject('No `onImageUpload` callback has been set.');
      },
      HTMLAttributes: {},
    };
  },

  addStorage() {
    return {
      onImageUpload: this.options.onImageUpload,
    };
  },

  group: 'block',
  draggable: true,
  isolating: true,
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: 'fit-content',
      },
      height: {
        default: null,
      },
      align: {
        default: 'left',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.options.allowBase64 ? 'img[src]' : 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { align, ...rest } = HTMLAttributes;

    const style = P.run(() => {
      switch (align) {
        case 'left': {
          return {
            display: 'block',
            'margin-right': 'inherit',
            'margin-left': 'inherit',
          };
        }
        case 'right': {
          return {
            display: 'block',
            'margin-left': 'auto',
            'margin-right': 'inherit',
          };
        }
        case 'center':
          return {
            display: 'block',
            'margin-right': 'auto',
            'margin-left': 'auto',
          };
      }
    });

    const attrs = mergeAttributes(this.options.HTMLAttributes, rest, {
      style,
    });

    return ['img', attrs];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageNodeView);
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;
          return { src, alt, title };
        },
      }),
    ];
  },
});
