import gql from 'graphql-tag';

export const Participant = gql`
  query Participant($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        organizationId
        firstName
        lastName
        gender
        dob
        address
        suburb
        postcode
        state
        status
        ndisNumber
        picture
        location {
          id
          name
          color
        }
        labels {
          id
          name
          description
          color
        }
        keyContacts {
          id
          name
          email
          phone
          description
          labels {
            id
            name
            description
            color
          }
        }
        assignedUsers {
          id
          user {
            id
            name
            email
            title
            picture
            organizationMemberships {
              organizationId
              name
              roles
            }
          }
          labels {
            id
            name
            description
            color
          }
        }
        fieldGroups {
          id
          template {
            name
            index
          }
          fields {
            id
            value
            template {
              name
              type
              attributes
              index
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        isOnLatestFields
        createdAt
        updatedAt
      }
    }
  }
`;

export const ParticipantMinimal = gql`
  query ParticipantMinimal($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        organizationId
        status
        firstName
        lastName
        isOnLatestFields
      }
    }
  }
`;

export const ParticipantUsers = gql`
  query ParticipantUsers($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        organizationId
        assignedUsers {
          id
          user {
            id
            name
            email
            title
            picture
            organizationMemberships {
              organizationId
              name
              roles
            }
          }
          labels {
            id
            name
            description
            color
          }
        }
      }
    }
  }
`;

export const ParticipantUsersMinimal = gql`
  query ParticipantUsersMinimal($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      participant(participantId: $participantId) {
        assignedUsers {
          id
        }
      }
    }
  }
`;

export const ParticipantQrs = gql`
  query ParticipantQrs($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        behaviours {
          id
          name
          status
          methods {
            abc {
              __typename
              id
              enabled
            }
            duration {
              __typename
              id
              enabled
            }
            episodicSeverity {
              __typename
              id
              enabled
            }
            frequency {
              __typename
              id
              enabled
            }
          }
        }
        qrs {
          id
          name
          status
          behaviours {
            id
            name
            status
            description
            example
            nonExample
            methods {
              abc {
                __typename
                id
                enabled
              }
              duration {
                __typename
                id
                enabled
              }
              episodicSeverity {
                __typename
                id
                enabled
              }
              frequency {
                __typename
                id
                enabled
              }
            }
            recordings {
              __typename
              ... on ParticipantBehaviourRecordingAbc {
                id
                user {
                  ... on User {
                    email
                  }
                  ... on UnauthedRecordingUser {
                    email
                  }
                }
                createdAt
              }
              ... on ParticipantBehaviourRecordingFrequency {
                id
                user {
                  ... on User {
                    email
                  }
                  ... on UnauthedRecordingUser {
                    email
                  }
                }
                createdAt
              }
              ... on ParticipantBehaviourRecordingDuration {
                id
                user {
                  ... on User {
                    email
                  }
                  ... on UnauthedRecordingUser {
                    email
                  }
                }
                createdAt
              }
              ... on ParticipantBehaviourRecordingEpisodicSeverity {
                id
                user {
                  ... on User {
                    email
                  }
                  ... on UnauthedRecordingUser {
                    email
                  }
                }
                createdAt
              }
            }
          }
          users {
            qrId
            email
            name
          }
          labels {
            id
            name
            description
            color
          }
          createdAt
        }
      }
    }
  }
`;

export const ParticipantRestrictedPractices = gql`
  query ParticipantRestrictedPractices($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        restrictedPractices {
          id
          type
          description
          identifiedAt
          startedAt
          ceasedAt
          status
          labels {
            id
            name
            description
            color
          }
          createdAt
        }
      }
    }
  }
`;

export const ParticipantBehaviours = gql`
  query ParticipantBehaviours($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        behaviours {
          id
          name
          status
          description
          example
          nonExample
          methods {
            abc {
              __typename
              id
              enabled
            }
            duration {
              __typename
              id
              enabled
            }
            episodicSeverity {
              __typename
              id
              enabled
              scales {
                id
                description
              }
            }
            frequency {
              __typename
              id
              enabled
            }
          }
        }
      }
    }
  }
`;

export const ParticipantBehavioursMinimal = gql`
  query ParticipantBehavioursMinimal($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      participant(participantId: $participantId) {
        behaviours {
          id
        }
      }
    }
  }
`;

export const ParticipantBehavioursRecordings = gql`
  query ParticipantBehavioursRecordings($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        behaviours {
          id
          name
          status
          description
          example
          nonExample
          methods {
            abc {
              __typename
              id
              enabled
            }
            duration {
              __typename
              id
              enabled
            }
            episodicSeverity {
              __typename
              id
              enabled
              scales {
                id
                description
              }
            }
            frequency {
              __typename
              id
              enabled
            }
          }
          recordings {
            __typename
            ... on ParticipantBehaviourRecordingFrequency {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              value
              timestamp
              comment
              createdAt
              user {
                __typename
                ... on User {
                  name
                  email
                  picture
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
            ... on ParticipantBehaviourRecordingDuration {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              value
              timestamp
              comment
              createdAt
              user {
                __typename
                ... on User {
                  name
                  email
                  picture
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
            ... on ParticipantBehaviourRecordingAbc {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              antecedent
              behaviour
              consequence
              timestamp
              comment
              createdAt
              user {
                __typename
                ... on User {
                  name
                  email
                  picture
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
            ... on ParticipantBehaviourRecordingEpisodicSeverity {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              value
              timestamp
              comment
              createdAt
              user {
                __typename
                ... on User {
                  name
                  email
                  picture
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ParticipantBehaviour = gql`
  query ParticipantBehaviour(
    $organizationId: ID!
    $participantId: ID!
    $participantBehaviourId: ID!
  ) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        behaviour(participantBehaviourId: $participantBehaviourId) {
          id
          name
          status
          description
          example
          nonExample
          methods {
            abc {
              __typename
              id
              enabled
            }
            duration {
              __typename
              id
              enabled
            }
            episodicSeverity {
              __typename
              id
              enabled
              scales {
                id
                description
              }
            }
            frequency {
              __typename
              id
              enabled
            }
          }
          recordings {
            __typename
            ... on ParticipantBehaviourRecordingFrequency {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              value
              timestamp
              comment
              createdAt
              method {
                __typename
                id
              }
              user {
                ... on User {
                  name
                  email
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
            ... on ParticipantBehaviourRecordingDuration {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              value
              timestamp
              comment
              createdAt
              user {
                ... on User {
                  name
                  email
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
            ... on ParticipantBehaviourRecordingAbc {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              antecedent
              behaviour
              consequence
              timestamp
              comment
              createdAt
              user {
                ... on User {
                  name
                  email
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
            ... on ParticipantBehaviourRecordingEpisodicSeverity {
              id
              participantBehaviourId
              method {
                __typename
                id
              }
              value
              timestamp
              comment
              createdAt
              user {
                ... on User {
                  name
                  email
                }
                ... on UnauthedRecordingUser {
                  name
                  email
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ParticipantNotes = gql`
  query ParticipantNotes($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        notes {
          id
          participantId
          user {
            id
            name
            email
            picture
          }
          labels {
            id
            name
            color
            description
          }
          value
          createdAt
        }
      }
    }
  }
`;

export const ParticipantNotesMinimal = gql`
  query ParticipantNotesMinimal($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      participant(participantId: $participantId) {
        notes {
          id
        }
      }
    }
  }
`;

export const ParticipantNote = gql`
  query ParticipantNote($organizationId: ID!, $participantId: ID!, $participantNoteId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        note(participantNoteId: $participantNoteId) {
          __typename
          id
          participantId
          user {
            id
            name
            email
            picture
          }
          labels {
            id
            name
            color
            description
          }
          value
          createdAt
        }
      }
    }
  }
`;

export const ParticipantNoteVersion = gql`
  query ParticipantNoteVersion(
    $organizationId: ID!
    $participantId: ID!
    $participantNoteId: ID!
    $versionId: ID!
  ) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        note(participantNoteId: $participantNoteId, versionId: $versionId) {
          __typename
          id
          versionId
          participantId
          value
        }
      }
    }
  }
`;

export const ParticipantNoteVersions = gql`
  query ParticipantNoteVersions(
    $organizationId: ID!
    $participantId: ID!
    $participantNoteId: ID!
    $request: GetPaginatedParticipantNoteVersionsRequest
  ) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        note(participantNoteId: $participantNoteId) {
          __typename
          id
          versionId
          participantId
          versions(request: $request) {
            pagination {
              total
              remaining
              offset
              limit
              currentPage
              totalPages
              hasNextPage
              hasPreviousPage
            }
            results {
              versionId
              createdAt
              users {
                id
                name
                email
                picture
              }
            }
          }
        }
      }
    }
  }
`;

export const ParticipantFiles = gql`
  query ParticipantFiles($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        files {
          __typename
          id
          participantId
          name
          size
          uploader {
            id
            name
            email
            picture
          }
          labels {
            id
            name
            description
            color
          }
          viewUrl
          downloadUrl
          createdAt
        }
      }
    }
  }
`;

export const ParticipantFilesMinimal = gql`
  query ParticipantFilesMinimal($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      participant(participantId: $participantId) {
        files {
          id
        }
      }
    }
  }
`;

export const ParticipantDocuments = gql`
  query ParticipantDocuments($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        documents {
          __typename
          id
          versionId
          participantId
          creator {
            id
            name
            email
            picture
          }
          labels {
            id
            name
            description
            color
          }
          name
          status
          authors {
            id
            name
            email
            picture
          }
          reviewers {
            id
            name
            email
            picture
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ParticipantDocumentsMinimal = gql`
  query ParticipantDocumentsMinimal($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      participant(participantId: $participantId) {
        documents {
          id
        }
      }
    }
  }
`;

export const ParticipantDocument = gql`
  query ParticipantDocument(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $versionId: ID
  ) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        document(participantDocumentId: $participantDocumentId, versionId: $versionId) {
          __typename
          id
          versionId
          participantId
          name
          status
          variables {
            id
            label
            category
            value
          }
          creator {
            id
            name
            email
            picture
          }
          labels {
            id
            name
            description
            color
          }
          authors {
            id
            name
            email
            picture
          }
          reviewers {
            id
            name
            email
            picture
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const ParticipantDocumentVersions = gql`
  query ParticipantDocumentVersions(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $request: GetPaginatedParticipantDocumentVersionsRequest
  ) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        document(participantDocumentId: $participantDocumentId) {
          __typename
          id
          versionId
          participantId
          versions(request: $request) {
            pagination {
              total
              remaining
              offset
              limit
              currentPage
              totalPages
              hasNextPage
              hasPreviousPage
            }
            results {
              versionId
              createdAt
              users {
                id
                name
                email
                picture
              }
            }
          }
        }
      }
    }
  }
`;

export const ParticipantDocumentVersion = gql`
  query ParticipantDocumentVersion(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $versionId: ID!
  ) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        document(participantDocumentId: $participantDocumentId, versionId: $versionId) {
          __typename
          id
          name
          versionId
          participantId
          status
          variables {
            id
            label
            category
            value
          }
          value
        }
      }
    }
  }
`;

export const ParticipantAuditTrailEntries = gql`
  query ParticipantAuditTrailEntries(
    $organizationId: ID!
    $participantId: ID!
    $request: GetPaginatedParticipantAuditTrailEntriesRequest
  ) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        entries(request: $request) {
          results {
            id
            organizationId
            timestamp
            user {
              id
              name
              email
              picture
            }
            event
            message
            payload
            createdAt
            updatedAt
          }
          pagination {
            total
            remaining
            offset
            limit
            currentPage
            totalPages
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export const ParticipantWidgetLayoutItems = gql`
  query ParticipantWidgetLayoutItems($organizationId: ID!, $participantId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participant(participantId: $participantId) {
        id
        widgets {
          id
          x
          y
          w
          h
          widget {
            __typename
            ... on TableWidget {
              id
              title
              series {
                id
                source {
                  __typename
                  ... on ParticipantBehaviourDataSource {
                    participantBehaviourId
                    method
                  }
                }
              }
            }
            ... on TimeSeriesChartWidget {
              __typename
              id
              title
              series {
                __typename
                ... on LineTimeSeries {
                  id
                  aggregation
                  color
                  source {
                    __typename
                    ... on ParticipantBehaviourDataSource {
                      participantBehaviourId
                      method
                    }
                  }
                  symbol
                  trendline {
                    id
                    type
                    name
                    color
                  }
                }
                ... on ScatterTimeSeries {
                  id
                  aggregation
                  color
                  source {
                    __typename
                    ... on ParticipantBehaviourDataSource {
                      participantBehaviourId
                      method
                    }
                  }
                  symbol
                  trendline {
                    id
                    type
                    name
                    color
                  }
                }
                ... on BarTimeSeries {
                  id
                  aggregation
                  color
                  source {
                    __typename
                    ... on ParticipantBehaviourDataSource {
                      participantBehaviourId
                      method
                    }
                  }
                  symbol
                  trendline {
                    id
                    type
                    name
                    color
                  }
                }
              }
              phaseChangeLines {
                id
                name
                type
                timestamp
                color
              }
            }
          }
        }
      }
    }
  }
`;
