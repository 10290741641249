import { Button, IconButton } from '@chakra-ui/react';
import { Command, FloatingPopover } from '@piccolohealth/ui';
import { Editor } from '@tiptap/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { getEditingPages } from '../extensions/slashmenu/pages';

interface AddNodeMenuProps {
  editor: Editor;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const AddNodeMenu = (props: AddNodeMenuProps) => {
  const { editor, isOpen, setOpen } = props;

  const state = {
    editor: editor,
    range: {
      from: editor.state.selection.anchor,
      to: editor.state.selection.anchor + 1,
    },
  };

  const variables = editor.storage?.variable?.variables ?? [];

  const getPages = getEditingPages(variables ?? []);

  return (
    <FloatingPopover
      isPortal
      shouldInitialFocus
      open={isOpen}
      setOpen={setOpen}
      placement="bottom-start"
      render={({ context }) => (
        <Command.CommandMenu ctx={state} onOpenChange={context.onOpenChange} getPages={getPages} />
      )}
    >
      <IconButton
        variant="ghost"
        tabIndex={-1}
        icon={<FaPlus />}
        aria-label="Add node"
        size="xs"
        color="gray.500"
      />
    </FloatingPopover>
  );
};

interface AddNodeButtonProps {
  editor: Editor;
}

export const AddNodeButton = (props: AddNodeButtonProps) => {
  const { editor } = props;

  const state = {
    editor: editor,
    range: {
      from: editor.state.selection.anchor,
      to: editor.state.selection.anchor + 1,
    },
  };

  const variables = editor.storage?.variable?.variables ?? [];

  const getPages = getEditingPages(variables ?? []);

  return (
    <FloatingPopover
      isPortal
      shouldInitialFocus
      placement="bottom-start"
      render={({ context }) => (
        <Command.CommandMenu ctx={state} onOpenChange={context.onOpenChange} getPages={getPages} />
      )}
    >
      {/*
      This is a button rendered out as a div to the frontend. Disgusting, but FloatingUI
      has a large bug in Safari. See https://github.com/floating-ui/floating-ui/issues/2533 
      */}
      <Button as="div" tabIndex={0} variant="ghost" size="sm" rounded="lg" leftIcon={<FaPlus />}>
        Insert
      </Button>
    </FloatingPopover>
  );
};
