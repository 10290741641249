import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ParticipantBehaviour,
  ParticipantQr,
  ParticipantQrStatus,
  UpdateParticipantQrBehaviourMethodsRequest,
  UpdateParticipantQrBehaviourRequest,
  UpdateParticipantQrRequest,
} from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useUpdateParticipantQRMutation } from '../../graphql/hooks/useUpdateParticipantQRMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantQRForm } from './ParticipantQRForm';

type FormValues = UpdateParticipantQrRequest;

const updateParticipantQrBehaviourMethodsRequestSchema: z.ZodSchema<UpdateParticipantQrBehaviourMethodsRequest> =
  z.object({
    abc: z.object({ enabled: z.boolean() }),
    frequency: z.object({ enabled: z.boolean() }),
    duration: z.object({ enabled: z.boolean() }),
    episodicSeverity: z.object({ enabled: z.boolean() }),
  });

export const behaviourSchema: z.ZodSchema<UpdateParticipantQrBehaviourRequest> = z.object({
  behaviourId: z.string(),
  methods: updateParticipantQrBehaviourMethodsRequestSchema,
});

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string().nullish(),
  status: z.nativeEnum(ParticipantQrStatus),
  behaviours: z.array(behaviourSchema),
  labelIds: z.array(z.string()).nullish(),
});

interface Props {
  qr: ParticipantQr;
  participantBehaviours: ParticipantBehaviour[];
  participantId: string;
}

export const ParticipantQREditModal = createModal<Props>((props) => {
  const { qr, participantBehaviours, participantId, modal } = props;

  const { hide, visible, remove } = modal;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateParticipantQRMutation();

  const initialValues: Partial<FormValues> = {
    name: qr.name,
    status: qr.status,
    behaviours: participantBehaviours.map((behaviour) => ({
      behaviourId: behaviour.id,
      methods: qr.behaviours.find((b) => b.id === behaviour.id)?.methods ?? {
        abc: { enabled: false },
        frequency: { enabled: false },
        duration: { enabled: false },
        episodicSeverity: { enabled: false },
      },
    })),
    labelIds: qr.labels.map((label) => label.id),
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const behavioursToSubmit = (values.behaviours ?? []).filter((behaviour) =>
        Object.values(behaviour.methods).some((method) => method.enabled),
      );

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantId,
          qrId: qr.id,
          request: {
            name: values.name,
            status: values.status,
            behaviours: behavioursToSubmit,
            labelIds: values.labelIds,
          },
        })
        .then(async () => {
          hide();
          successToast('QR code updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating QR code: ${err.message}`);
        });
    },
    [mutation, organization.id, participantId, qr.id, hide, successToast, errorToast],
  );

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <HStack>
                <Box>
                  <Heading size="md">Edit QR code</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Change QR code details
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </HStack>
            </ModalHeader>
            <ModalBody>
              <ParticipantQRForm participantBehaviours={participantBehaviours} />
            </ModalBody>
            <ModalFooter>
              <ButtonGroup size="sm">
                <Button key="close" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Save</HookedSubmitButton>
              </ButtonGroup>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
