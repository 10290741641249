import React from 'react';

export const useDelayedLoading = (delay = 1000) => {
  const [minSkeletonDelayed, setMinSkeletonDelayed] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setMinSkeletonDelayed(false);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  return minSkeletonDelayed;
};
