import gql from 'graphql-tag';

export const CreateParticipantQr = gql`
  mutation CreateParticipantQr(
    $organizationId: ID!
    $participantId: ID!
    $request: CreateParticipantQrRequest!
  ) {
    createParticipantQr(
      organizationId: $organizationId
      participantId: $participantId
      request: $request
    ) {
      id
    }
  }
`;
