import { Divider, Flex, Icon } from '@chakra-ui/react';
import { Location, Role } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FaUser, FaUsers, FaUsersCog } from 'react-icons/fa';
import { FormStack } from '../../components/forms/FormStack';
import { HookedAvatarUploader } from '../../components/forms/HookedAvatarUploader';
import { HookedCheckboxStack } from '../../components/forms/HookedCheckboxStack';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedInput } from '../../components/forms/HookedInput';
import { HookedMultiSelect } from '../../components/forms/HookedMultiSelect';
import { Error } from '../../components/generic/Error';
import { useOrganizationQuery } from '../../graphql/hooks/useOrganizationQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const UserForm = () => {
  const { organization } = useAppContext();

  const roles = useWatch({ name: 'roles' });

  const { setValue } = useFormContext();

  const { isLoading, data, error } = useOrganizationQuery({
    organizationId: organization.id,
  });

  const canSelectLocations = React.useMemo(() => {
    return roles.includes(Role.TeamLeader) || roles.includes(Role.TeamMember);
  }, [roles]);

  const locationOptions = React.useMemo(() => {
    const locations = (data?.organization?.locations as Location[]) ?? [];

    const options = locations.map((location) => ({
      label: location.name,
      value: location.id,
      raw: location,
      color: location.color,
    }));

    return P.orderBy(options, (o) => o.label);
  }, [data?.organization?.locations]);

  React.useEffect(() => {
    if (!canSelectLocations) {
      setValue('locations', []);
    }
  }, [canSelectLocations, setValue]);

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <FormStack>
      <FormStack orientation="horizontal" spacing={8}>
        <FormStack spacing={5}>
          <HookedFormItem name="name" label="Name" isRequired>
            <HookedInput name="name" size="sm" placeholder="Enter name" />
          </HookedFormItem>
          <HookedFormItem name="email" label="Email" isRequired>
            <HookedInput name="email" size="sm" placeholder="Enter email" />
          </HookedFormItem>
          <HookedFormItem name="title" label="Title" isRequired>
            <HookedInput name="title" size="sm" placeholder="Enter title" />
          </HookedFormItem>
          <HookedFormItem name="practitionerNumber" label="Practitioner number">
            <HookedInput
              name="practitionerNumber"
              size="sm"
              placeholder="Enter practitioner number"
            />
          </HookedFormItem>
        </FormStack>

        <Flex pt={7}>
          <HookedAvatarUploader name="picture" size="xl" />
        </Flex>
      </FormStack>

      <Divider />

      <HookedFormItem name="roles" label="Roles">
        <HookedCheckboxStack
          name="roles"
          options={[
            {
              label: 'Administrator',
              value: Role.Administrator,
              description:
                'Can manage all aspects of the organization. Has access to all participants',
              icon: () => <Icon as={FaUsersCog} />,
            },
            {
              label: 'Team leader',
              value: Role.TeamLeader,
              description:
                'Can manage all aspects of their team. Has access to only the participants they are assigned',
              icon: () => <Icon as={FaUsers} />,
            },
            {
              label: 'Team member',
              value: Role.TeamMember,
              description:
                'Has access to only the participants they are assigned. Cannot manage participants',
              icon: () => <Icon as={FaUser} />,
            },
          ]}
        />
      </HookedFormItem>
      {canSelectLocations && (
        <HookedFormItem name="locations" label="Locations">
          <HookedMultiSelect
            placeholder="Select locations"
            name="locations"
            options={locationOptions}
            optionVariant="tag"
          />
        </HookedFormItem>
      )}
    </FormStack>
  );
};
