import {
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
} from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Error } from '../../components/generic/Error';
import { TabsRouter } from '../../components/generic/TabsRouter';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useParticipantMinimalQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantBehavioursBadge } from '../behaviours/components/ParticipantBehavioursBadge';
import { ParticipantBehaviours } from '../behaviours/ParticipantBehaviours';
import { ParticipantDashboard } from '../dashboard/ParticipantDashboard';
import { ParticipantFilesAndDocuments } from '../files/ParticipantFilesAndDocuments';
import { ParticipantNotes } from '../notes/ParticipantNotes';
import { ParticipantNotesBadge } from '../notes/ParticipantNotesBadge';
import { ParticipantDataBadge } from '../participantData/components/ParticipantDataBadge';
import { ParticipantData } from '../participantData/ParticipantData';
import { ParticipantDocumentsBadge } from '../participantDocument/ParticipantDocumentsBadge';
import { ParticipantTeam } from '../participantTeam/ParticipantTeam';
import { ParticipantTeamBadge } from '../participantTeam/ParticipantTeamBadge';
import { ParticipantTimelineControl } from '../participantTimeline/ParticipantTimelineControl';
import { ParticipantRestrictedPracticesBadge } from '../restrictedPractices/components/ParticipantRestrictredPracticesBadge';
import { ParticipantRestrictedPractices } from '../restrictedPractices/ParticipantRestrictedPractices';
import { ParticipantDisabledStatus } from './ParticipantDisabledStatus';
import { ParticipantVersionStatus } from './ParticipantVersionStatus';

export const Participant = () => {
  const { participantId } = useRequiredParams<{ participantId: string }>();

  const { organization } = useAppContext();
  const navigate = useNavigate();

  const { data, error, isLoading } = useParticipantMinimalQuery({
    organizationId: organization.id,
    participantId,
  });

  const participant = data?.organization?.participant;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!participant) {
    return (
      <Error
        error={
          new Common.PiccoloError({
            type: 'ParticipantNotFound',
            message: 'Participant not found',
          })
        }
      />
    );
  }

  const isDisabled = Common.isParticipantDisabled(participant.status);

  return (
    <ContentLayout
      headerContent={
        <HStack>
          <Heading size="lg">{Common.renderParticipantName(participant)}</Heading>
          <Spacer />
          <ParticipantTimelineControl participantId={participant.id} />
        </HStack>
      }
    >
      <Flex h="full">
        <Stack spacing={6} h="full" w="full">
          <TabsRouter
            variant="solid"
            defaultIndex={0}
            size="sm"
            w="full"
            h="full"
            display="flex"
            flexDir="column"
            isLazy
            lazyBehavior="unmount"
            routes={[
              'dashboard',
              'team',
              'documents',
              'behaviours',
              'restrictedPractices',
              'data',
              'notes',
            ]}
          >
            <Stack px={6} spacing={4}>
              <Stack>
                <ParticipantDisabledStatus status={participant.status} />
                {!participant.isOnLatestFields && (
                  <ParticipantVersionStatus participantId={participant.id} />
                )}
              </Stack>

              <TabList>
                <Tab key="dashboard">Dashboard</Tab>
                <Tab key="team">
                  <HStack>
                    <Text>Team</Text>
                    <ParticipantTeamBadge participantId={participant.id} />
                  </HStack>
                </Tab>
                {/* Workaround to navigate to an nested route using TabsRouter.
                Chakra doesnt let you customise the onClick hamdlers nicely. */}
                <Tab key="documents" onClick={() => navigate(`documents`)}>
                  <HStack>
                    <Text>Documents</Text>
                    <ParticipantDocumentsBadge participantId={participant.id} />
                  </HStack>
                </Tab>
                <Tab key="behaviours">
                  <HStack>
                    <Text>Behaviours</Text>
                    <ParticipantBehavioursBadge participantId={participant.id} />
                  </HStack>
                </Tab>

                <Tab key="restrictedPractices">
                  <HStack>
                    <Text>Restricted Practices</Text>
                    <ParticipantRestrictedPracticesBadge participantId={participant.id} />
                  </HStack>
                </Tab>
                <Tab key="data">
                  <HStack>
                    <Text>Data</Text>
                    <ParticipantDataBadge participantId={participant.id} />
                  </HStack>
                </Tab>
                <Tab key="notes" onClick={() => navigate('notes')}>
                  <HStack>
                    <Text>Notes</Text>
                    <ParticipantNotesBadge participantId={participant.id} />
                  </HStack>
                </Tab>
              </TabList>
            </Stack>

            <TabPanels overflowY="auto" h="full" w="full">
              <TabPanel h="full">
                <ParticipantDashboard participantId={participant.id} isDisabled={isDisabled} />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantTeam participantId={participant.id} isDisabled={isDisabled} />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantFilesAndDocuments
                  participantId={participant.id}
                  isDisabled={isDisabled}
                />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantBehaviours participantId={participant.id} isDisabled={isDisabled} />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantRestrictedPractices
                  participantId={participant.id}
                  isDisabled={isDisabled}
                />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantData participantId={participant.id} isDisabled={isDisabled} />
              </TabPanel>
              <TabPanel>
                <ParticipantNotes participantId={participant.id} isDisabled={isDisabled} />
              </TabPanel>
            </TabPanels>
          </TabsRouter>
        </Stack>
      </Flex>
    </ContentLayout>
  );
};
