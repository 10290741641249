import gql from 'graphql-tag';

export const CreateParticipantQrLabel = gql`
  mutation CreateParticipantQrLabel(
    $organizationId: ID!
    $participantId: ID!
    $qrId: ID!
    $labelId: ID!
  ) {
    createParticipantQrLabel(
      organizationId: $organizationId
      participantId: $participantId
      qrId: $qrId
      labelId: $labelId
    ) {
      id
    }
  }
`;
