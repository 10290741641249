import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../hooks/useAppContext';
import { Spin } from '@piccolohealth/ui';

export const ProtectedRoute = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { config, auth, user } = useAppContext();
  const location = useLocation();

  if (auth.isLoading || user.isLoading) {
    return <Spin />;
  }

  if (!auth.isAuthenticated) {
    auth.loginWithRedirect({
      redirectUri: config.auth.redirectUri,
      appState: {
        targetUrl: location?.pathname,
      },
    });
    return <Spin />;
  }

  return <>{children}</>;
};
