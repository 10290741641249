import { Button, HStack, Icon } from '@chakra-ui/react';
import { dataAttr } from '@piccolohealth/ui';
import React from 'react';
import { FaChartBar, FaTable } from 'react-icons/fa';

interface Props {
  value: number;
  onChange: (index: number) => void;
}

export const ParticipantDataTabButtons = (props: Props) => {
  const { value, onChange } = props;

  const buttonSx = {
    bg: 'gray.100',
    color: 'gray.400',
    rounded: 'md',
    fontSize: 'sm',
    fontWeight: 'semibold',
    py: 1,
    px: 4,
    h: 'auto',
    lineHeight: 'inherit',
    _selected: {
      bg: 'white',
      color: 'gray.600',
    },
  };

  return (
    <HStack w="fit-content" p={1} spacing={0} bg="gray.100" rounded="lg">
      <Button
        size="sm"
        sx={buttonSx}
        onClick={() => onChange(0)}
        leftIcon={<Icon as={FaChartBar} />}
        {...{ 'data-selected': dataAttr(value === 0) }}
      >
        Widgets view
      </Button>
      <Button
        size="sm"
        sx={buttonSx}
        onClick={() => onChange(1)}
        leftIcon={<Icon as={FaTable} />}
        {...{ 'data-selected': dataAttr(value === 1) }}
      >
        Table view
      </Button>
    </HStack>
  );
};
