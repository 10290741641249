import { Box, Button, Divider, HStack, Input, Spacer, Stack, Text } from '@chakra-ui/react';
import { RestrictedPracticeStatus, RestrictedPracticeType } from '@piccolohealth/pbs-common';
import {
  FloatingPopover,
  MultiSelect,
  MultiSelectOption,
  NumberedBadge,
  OnChangeRequest,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCaretDown, FaFilter } from 'react-icons/fa';
import { FormItem } from '../../../components/forms/FormItem';
import { FormStack } from '../../../components/forms/FormStack';
import { ParticipantRestrictedPracticesFilter } from '../hooks/useParticipantRestrictedPracticesFilter';
import { restrictedPracticeTypeOptions } from './RestrictedPracticeTypeTag';
import { restrictedPracticeStatusOptions } from './RestrictedPracticeStatusTag';

interface Props {
  filter: ParticipantRestrictedPracticesFilter;
}

const ParticipantRestrictedPracticeDescriptionFilterControl = (props: Props) => {
  const [value, setValue] = React.useState(props.filter.descriptionFilter);

  const onChange = React.useCallback(
    (value: string) => {
      setValue(value);
      props.filter.onDescriptionFilter(value);
    },
    [props.filter],
  );

  React.useEffect(() => {
    setValue(props.filter.descriptionFilter);
  }, [props.filter.descriptionFilter]);

  return (
    <Input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder="All restricted practices"
      size="sm"
    />
  );
};

const RestrictedPracticeTypeFilterControl = (props: Props) => {
  const options: MultiSelectOption<RestrictedPracticeType>[] = Object.values(
    restrictedPracticeTypeOptions,
  );

  const selectedValues: MultiSelectOption<RestrictedPracticeType>[] = React.useMemo(() => {
    return (props.filter.typeFilter ?? []).flatMap((status) => {
      return options.find((o) => P.isEqual(o.raw, status)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<RestrictedPracticeType>) => {
      props.filter.onTypeFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All types"
      optionVariant="tag"
    />
  );
};

const RestrictedPracticeStatusFilterControl = (props: Props) => {
  const options: MultiSelectOption<RestrictedPracticeStatus>[] = Object.values(
    restrictedPracticeStatusOptions,
  );

  const selectedValues: MultiSelectOption<RestrictedPracticeStatus>[] = React.useMemo(() => {
    return (props.filter.statusFilter ?? []).flatMap((status) => {
      return options.find((o) => P.isEqual(o.raw, status)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<RestrictedPracticeStatus>) => {
      props.filter.onStatusFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All statuses"
      optionVariant="tag"
    />
  );
};

export const ParticipantRestrictedPracticeFilterControls = (props: Props) => {
  return (
    <FormStack orientation="horizontal">
      <Box maxW="2xs">
        <ParticipantRestrictedPracticeDescriptionFilterControl filter={props.filter} />
      </Box>
      <FloatingPopover
        isPortal
        render={() => (
          <Stack bg="white" spacing={0} w="xs" shadow="popover" rounded="md" overflow="hidden">
            <HStack py={2} px={4} borderBottomWidth="1px">
              <Text fontWeight="semibold">Filters</Text>
              <Spacer />
              <NumberedBadge count={props.filter.activeFilterCount} />
            </HStack>
            <FormStack py={4} px={4} orientation="vertical">
              <FormItem label="Type">
                <RestrictedPracticeTypeFilterControl filter={props.filter} />
              </FormItem>
              <FormItem label="Status">
                <RestrictedPracticeStatusFilterControl filter={props.filter} />
              </FormItem>
              <Divider />

              <Button
                w="fit-content"
                size="sm"
                variant="outline"
                isDisabled={props.filter.activeFilterCount === 0}
                onClick={props.filter.reset}
              >
                Reset
              </Button>
            </FormStack>
          </Stack>
        )}
      >
        <Button
          size="sm"
          variant="outline"
          lineHeight="unset"
          leftIcon={<FaFilter />}
          rightIcon={<FaCaretDown />}
        >
          <HStack>
            <Text>Filter</Text>
            <Box w="28px">
              <NumberedBadge count={props.filter.activeFilterCount} />
            </Box>
          </HStack>
        </Button>
      </FloatingPopover>
    </FormStack>
  );
};
