import React from 'react';
import {
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ParticipantQr } from '@piccolohealth/pbs-common';
import { DateTime, inflection, P } from '@piccolohealth/util';
import { FaInfoCircle } from 'react-icons/fa';
import { UserDescription } from '../../components/user/UserDescription';
import { FancyDate } from '@piccolohealth/ui';

interface Props {
  qr: ParticipantQr;
}

export const ParticipantQRRecordingsPopover = (props: Props) => {
  const { qr } = props;

  const recordingsByUser = React.useMemo(() => {
    return P.mapValues(
      P.groupBy(
        qr.behaviours.flatMap((behaviour) => behaviour.recordings),
        (recording) => recording.user.email,
      ),
      (recordings) => recordings.length,
    );
  }, [qr.behaviours]);

  const lastUsed = React.useMemo(() => {
    const recordings = qr.behaviours.flatMap((behaviour) => behaviour.recordings);
    const latestRecording = P.maxBy(recordings, (recording) =>
      DateTime.fromISO(recording.createdAt as unknown as string).toUnixInteger(),
    );
    return latestRecording?.createdAt;
  }, [qr]);

  if (!lastUsed) {
    return <Text>Not used yet</Text>;
  }

  return (
    <HStack spacing={1}>
      <Text>Last used:</Text>
      <FancyDate
        date={lastUsed}
        format={DateTime.DATE_SHORT}
        direction="row"
        showHumanized={false}
      />

      <Popover trigger="hover" isLazy={true} lazyBehavior="unmount">
        <PopoverTrigger>
          <IconButton
            icon={<Icon as={FaInfoCircle} />}
            size="sm"
            aria-label="User recording details"
            variant="link"
          />
        </PopoverTrigger>
        <PopoverContent w="sm">
          <PopoverBody>
            <Stack spacing={4}>
              <Text fontWeight="semibold">
                QR has been used by {qr.users.length} {inflection.inflect('user', qr.users.length)}
              </Text>

              <Stack>
                {qr.users.map((user) => (
                  <HStack key={user.email} spacing={7} align="center" flexShrink={0}>
                    <UserDescription name={user.name} secondary={user.email} />
                    <Spacer />
                    <Text>
                      {recordingsByUser[user.email]}{' '}
                      {inflection.inflect('recording', recordingsByUser[user.email])}
                    </Text>
                  </HStack>
                ))}
              </Stack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};
