import gql from 'graphql-tag';

export const UpdateParticipantBehaviourRecording = gql`
  mutation UpdateParticipantBehaviourRecording(
    $organizationId: ID!
    $participantId: ID!
    $participantBehaviourId: ID!
    $recordingId: ID!
    $request: UpdateParticipantBehaviourRecordingRequest!
  ) {
    updateParticipantBehaviourRecording(
      organizationId: $organizationId
      participantId: $participantId
      participantBehaviourId: $participantBehaviourId
      recordingId: $recordingId
      request: $request
    ) {
      __typename
    }
  }
`;
