import React from 'react';
import { createRoot, Root } from 'react-dom/client';

export const useTooltipRef = () => {
  const tooltipRef = React.useRef<HTMLDivElement>(document.createElement('div'));
  const rootRef = React.useRef<Root | null>(null);

  React.useEffect(() => {
    if (tooltipRef.current) {
      rootRef.current = createRoot(tooltipRef.current);
    }

    return () => {
      if (rootRef.current) {
        rootRef.current.unmount();
      }
    };
  }, []);

  const tooltipRender = React.useCallback((children: React.ReactNode) => {
    if (rootRef.current && tooltipRef.current) {
      rootRef.current.render(children);
      return tooltipRef.current.innerHTML;
    }
  }, []);

  return tooltipRender;
};
