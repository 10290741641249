import NiceModal from '@ebay/nice-modal-react';
import { KnockFeedProvider, KnockProvider } from '@knocklabs/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { VersionCheckObserver } from '../../components/generic/VersionCheckObserver';
import { SidebarLayout } from '../../components/layouts/SidebarLayout';
import { OrganizationContextProvider } from '../../context/OrganizationContext';
import { useAppContext } from '../../hooks/useAppContext';
import { OrganizationSidebar } from './OrganizationSidebar';

export const OrganizationLayout = () => {
  const { user, config } = useAppContext();

  return (
    <OrganizationContextProvider>
      <KnockProvider apiKey={config.knock.apiKey} userId={user.id}>
        <KnockFeedProvider
          feedId={config.knock.feedId}
          defaultFeedOptions={{
            page_size: 10,
          }}
        >
          <NiceModal.Provider>
            <VersionCheckObserver />
            <SidebarLayout
              sidebarContent={(props) => <OrganizationSidebar {...props} />}
              mainContent={<Outlet />}
            />
          </NiceModal.Provider>
        </KnockFeedProvider>
      </KnockProvider>
    </OrganizationContextProvider>
  );
};
