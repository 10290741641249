import { ChakraProvider as V2ChakraProvider } from '@chakra-ui/react';
import { ChakraV3 } from '@piccolohealth/ui';
import React from 'react';
import { theme } from './theme';

const V3System = ChakraV3.createSystem(
  ChakraV3.defaultConfig,
  ChakraV3.defineConfig({
    cssVarsPrefix: 'v3',
    globalCss: {
      '*': {
        fontFeatureSettings: 'normal',
      },
      body: {
        colorPalette: 'purple',
      },
    },
    theme: {
      semanticTokens: {
        colors: {
          bg: {},
        },
        shadows: {},
      },
    },
  }),
);

export const ChakraV2Provider = (
  props: React.PropsWithChildren<{
    theme?: any;
  }>,
) => {
  return (
    <V2ChakraProvider theme={props.theme ?? theme} resetCSS={false} cssVarsRoot="#app">
      {props.children}
    </V2ChakraProvider>
  );
};

export const ChakraV3Provider = (props: React.PropsWithChildren<{}>) => {
  return <ChakraV3.ChakraProvider value={V3System}>{props.children}</ChakraV3.ChakraProvider>;
};

export const ChakraProvider = (
  props: React.PropsWithChildren<{
    v2Theme?: any;
  }>,
) => {
  return (
    <ChakraV3Provider>
      <ChakraV2Provider theme={props.v2Theme}>{props.children}</ChakraV2Provider>
    </ChakraV3Provider>
  );
};
