import ResizeTextarea from 'react-textarea-autosize';
import React from 'react';
import { Textarea, forwardRef, TextareaProps } from '@chakra-ui/react';

export interface Props extends TextareaProps {
  minRows?: number;
}

export const TextareaAutosize = forwardRef<Props, 'textarea'>((props, ref) => (
  <Textarea
    minH="unset"
    overflow="hidden"
    w="100%"
    resize="none"
    ref={ref}
    minRows={3}
    as={ResizeTextarea}
    {...props}
  />
));
