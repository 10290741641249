import { Tag, TagLabel, TagProps } from '@chakra-ui/react';
import { RestrictedPracticeType } from '@piccolohealth/pbs-common';
import React from 'react';

export const restrictedPracticeTypeOptions = {
  [RestrictedPracticeType.ChemicalRestraint]: {
    label: 'Chemical restraint',
    value: RestrictedPracticeType.ChemicalRestraint,
    raw: RestrictedPracticeType.ChemicalRestraint,
    color: 'green',
  },
  [RestrictedPracticeType.EnvironmentalRestraint]: {
    label: 'Environmental restraint',
    value: RestrictedPracticeType.EnvironmentalRestraint,
    raw: RestrictedPracticeType.EnvironmentalRestraint,
    color: 'blue',
  },
  [RestrictedPracticeType.MechanicalRestraint]: {
    label: 'Mechanical restraint',
    value: RestrictedPracticeType.MechanicalRestraint,
    raw: RestrictedPracticeType.MechanicalRestraint,
    color: 'red',
  },
  [RestrictedPracticeType.PhysicalRestraint]: {
    label: 'Physical restraint',
    value: RestrictedPracticeType.PhysicalRestraint,
    raw: RestrictedPracticeType.PhysicalRestraint,
    color: 'yellow',
  },
  [RestrictedPracticeType.Seclusion]: {
    label: 'Seclusion',
    value: RestrictedPracticeType.Seclusion,
    raw: RestrictedPracticeType.Seclusion,
    color: 'orange',
  },
};

interface Props extends TagProps {
  type: RestrictedPracticeType;
}

export const RestrictedPracticeTypeTag = (props: Props) => {
  const { type, ...rest } = props;

  const option = restrictedPracticeTypeOptions[type];

  return (
    <Tag size="sm" colorScheme={option.color} {...rest}>
      <TagLabel>{option.label}</TagLabel>
    </Tag>
  );
};
