import gql from 'graphql-tag';

export const DeleteParticipantQrLabel = gql`
  mutation DeleteParticipantQrLabel(
    $organizationId: ID!
    $participantId: ID!
    $qrId: ID!
    $labelId: ID!
  ) {
    deleteParticipantQrLabel(
      organizationId: $organizationId
      participantId: $participantId
      qrId: $qrId
      labelId: $labelId
    )
  }
`;
