import gql from 'graphql-tag';

export const DeleteParticipantRestrictedPractice = gql`
  mutation deleteParticipantRestrictedPractice(
    $organizationId: ID!
    $participantId: ID!
    $restrictedPracticeId: ID!
  ) {
    deleteParticipantRestrictedPractice(
      organizationId: $organizationId
      participantId: $participantId
      restrictedPracticeId: $restrictedPracticeId
    )
  }
`;
