import gql from 'graphql-tag';

export const DeleteParticipantBehaviourRecording = gql`
  mutation DeleteParticipantBehaviourRecording(
    $organizationId: ID!
    $participantId: ID!
    $participantBehaviourId: ID!
    $recordingId: ID!
  ) {
    deleteParticipantBehaviourRecording(
      organizationId: $organizationId
      participantId: $participantId
      participantBehaviourId: $participantBehaviourId
      recordingId: $recordingId
    )
  }
`;
