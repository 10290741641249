import { Button, Icon, IconButton } from '@chakra-ui/react';
import { CreateParticipantKeyContactRequest, LabelType } from '@piccolohealth/pbs-common';
import { DndKitCore, DndKitSortable, DragHandle, Sortable } from '@piccolohealth/ui';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { FormStack } from './FormStack';
import { HookedFormItem } from './HookedFormItem';
import { HookedInput } from './HookedInput';
import { HookedLabelChooser } from './HookedLabelChooser';

interface KeyContactItemProps {
  contact: CreateParticipantKeyContactRequest;
  name: string;
  isDisabled?: boolean;
  onRemove: () => void;
}

const KeyContactItem = (props: KeyContactItemProps) => {
  const { contact, name, isDisabled, onRemove } = props;

  const { attributes, setNodeRef, listeners, transform, transition } = DndKitSortable.useSortable({
    id: contact.id,
    disabled: isDisabled,
  });

  return (
    <Sortable ref={setNodeRef} transform={transform} transition={transition}>
      <FormStack orientation="horizontal" key={`contact-${contact.id}`} alignItems="center">
        <DragHandle listeners={listeners} attributes={attributes} size="xs" />
        <FormStack spacing={4} pl={4} ml={1} borderLeftWidth="2px" borderColor="purple.400">
          <FormStack orientation="horizontal">
            <HookedFormItem
              name={`${name}.name`}
              label="Name"
              labelSize="sm"
              helperText="The contacts name"
              isDisabled={isDisabled}
            >
              <HookedInput name={`${name}.name`} size="sm" placeholder="Enter a name" />
            </HookedFormItem>
            <HookedFormItem
              name={`${name}.email`}
              label="Email"
              labelSize="sm"
              helperText="The contacts email"
              isDisabled={isDisabled}
            >
              <HookedInput name={`${name}.email`} size="sm" placeholder="Enter an email " />
            </HookedFormItem>
          </FormStack>
          <FormStack orientation="horizontal">
            <HookedFormItem
              name={`${name}.phone`}
              label="Phone"
              labelSize="sm"
              helperText="The contacts phone"
              isDisabled={isDisabled}
            >
              <HookedInput name={`${name}.phone`} size="sm" placeholder="Enter a phone number" />
            </HookedFormItem>
            <HookedFormItem
              name={`${name}.description`}
              label="Description/relationship"
              labelSize="sm"
              helperText="Who is the contact?"
              isDisabled={isDisabled}
            >
              <HookedInput
                name={`${name}.description`}
                size="sm"
                placeholder="Enter a description or relationship"
              />
            </HookedFormItem>
          </FormStack>
          <FormStack orientation="horizontal">
            <HookedFormItem
              name={`${name}.labelIds`}
              label="Labels"
              labelSize="sm"
              isDisabled={isDisabled}
            >
              <HookedLabelChooser
                name={`${name}.labelIds`}
                type={LabelType.ParticipantKeyContact}
                size="sm"
                isDisabled={isDisabled}
              />
            </HookedFormItem>
          </FormStack>
        </FormStack>
        <IconButton
          aria-label="Remove key contact"
          size="xs"
          variant="ghost"
          icon={<FaTrash />}
          isDisabled={isDisabled}
          onClick={onRemove}
        />
      </FormStack>
    </Sortable>
  );
};

interface Props {
  name: string;
  isDisabled?: boolean;
}

export const HookedKeyContactsControl = (props: Props) => {
  const { name, isDisabled } = props;

  const { fields, append, remove, move } = useFieldArray({
    name,
  });
  const sensors = DndKitCore.useSensors(
    DndKitCore.useSensor(DndKitCore.PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const contacts = fields as unknown as CreateParticipantKeyContactRequest[];

  const onAdd = React.useCallback(() => {
    append({
      id: uuid(),
      name: null,
      phone: null,
      email: null,
      description: null,
      labelIds: [],
    });
  }, [append]);

  const onRemove = React.useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const onDragEnd = React.useCallback(
    async (event: DndKitCore.DragEndEvent) => {
      const { active, over } = event;

      if (!active || !over) {
        return;
      }

      const oldIndex = contacts.findIndex((item) => item.id === active.id);
      const newIndex = contacts.findIndex((item) => item.id === over.id);

      if (oldIndex === newIndex) {
        return;
      }

      move(oldIndex, newIndex);
    },
    [contacts, move],
  );

  return (
    <FormStack>
      <DndKitCore.DndContext
        sensors={sensors}
        collisionDetection={DndKitCore.closestCenter}
        onDragEnd={onDragEnd}
      >
        <DndKitSortable.SortableContext
          strategy={DndKitSortable.verticalListSortingStrategy}
          items={contacts.map(({ id }) => id)}
        >
          {contacts.map((contact, index) => {
            return (
              <KeyContactItem
                key={contact.id}
                contact={contact}
                name={`${name}.${index}`}
                isDisabled={isDisabled}
                onRemove={() => onRemove(index)}
              />
            );
          })}
        </DndKitSortable.SortableContext>
      </DndKitCore.DndContext>
      <Button size="sm" isDisabled={isDisabled} onClick={onAdd} leftIcon={<Icon as={FaPlus} />}>
        Add a contact
      </Button>
    </FormStack>
  );
};
