import * as Common from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getParticipantNoteExtensions, ydocToHtml } from '../../components/tiptap/utils';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantFilesAndDocumentsFilter } from '../../hooks/useParticipantFilesAndDocumentsFilter';
import { ParticipantNotesFilter } from '../../hooks/useParticipantNotesFilter';
import { createGqlQuery, InfiniteQueryOptions } from '../fetcher';
import {
  Participant,
  ParticipantAuditTrailEntries,
  ParticipantBehaviour,
  ParticipantBehaviours,
  ParticipantBehavioursMinimal,
  ParticipantBehavioursRecordings,
  ParticipantDocument,
  ParticipantDocuments,
  ParticipantDocumentsMinimal,
  ParticipantDocumentVersion,
  ParticipantDocumentVersions,
  ParticipantFiles,
  ParticipantFilesMinimal,
  ParticipantMinimal,
  ParticipantNote,
  ParticipantNotes,
  ParticipantNotesMinimal,
  ParticipantNoteVersion,
  ParticipantNoteVersions,
  ParticipantQrs,
  ParticipantRestrictedPractices,
  ParticipantUsers,
  ParticipantUsersMinimal,
  ParticipantWidgetLayoutItems,
} from '../queries/participant.query';
import * as Types from '../types';

export const getParticipantBaseKey = (variables: Types.ParticipantMinimalQueryVariables) => [
  'participant',
  variables,
];

export const useParticipantQuery = createGqlQuery<
  Types.ParticipantQueryVariables,
  Types.ParticipantQuery
>((variables) => ['participant', variables], Participant);

export const useParticipantMinimalQuery = createGqlQuery<
  Types.ParticipantMinimalQueryVariables,
  Types.ParticipantMinimalQuery
>((variables) => ['participant', variables, 'minimal'], ParticipantMinimal);

export const useParticipantUsersQuery = createGqlQuery<
  Types.ParticipantUsersQueryVariables,
  Types.ParticipantUsersQuery
>((variables) => ['participant', variables, 'users'], ParticipantUsers);

export const useParticipantUsersMinimalQuery = createGqlQuery<
  Types.ParticipantUsersMinimalQueryVariables,
  Types.ParticipantUsersMinimalQuery
>((variables) => ['participant', variables, 'users', 'minimal'], ParticipantUsersMinimal);

export const useParticipantQrsQuery = createGqlQuery<
  Types.ParticipantQrsQueryVariables,
  Types.ParticipantQrsQuery
>((variables) => ['participant', variables, 'qrs'], ParticipantQrs);

export const useParticipantRestrictedPracticesQuery = createGqlQuery<
  Types.ParticipantRestrictedPracticesQueryVariables,
  Types.ParticipantRestrictedPracticesQuery
>((variables) => ['participant', variables, 'restrictedPractices'], ParticipantRestrictedPractices);

export const useParticipantBehavioursQuery = createGqlQuery<
  Types.ParticipantBehavioursQueryVariables,
  Types.ParticipantBehavioursQuery
>((variables) => ['participant', variables, 'behaviours'], ParticipantBehaviours);

export const useParticipantBehavioursMinimalQuery = createGqlQuery<
  Types.ParticipantBehavioursMinimalQueryVariables,
  Types.ParticipantBehavioursMinimalQuery
>((variables) => ['participant', variables, 'behaviours', 'minimal'], ParticipantBehavioursMinimal);

export const useParticipantBehavioursRecordingsQuery = createGqlQuery<
  Types.ParticipantBehavioursRecordingsQueryVariables,
  Types.ParticipantBehavioursRecordingsQuery
>(
  (variables) => ['participant', variables, 'behaviours', 'recordings'],
  ParticipantBehavioursRecordings,
);

export const useParticipantBehaviourQuery = createGqlQuery<
  Types.ParticipantBehaviourQueryVariables,
  Types.ParticipantBehaviourQuery
>((variables) => ['participant', variables, 'behaviour'], ParticipantBehaviour);

export const useParticipantNotesQuery = createGqlQuery<
  Types.ParticipantNotesQueryVariables,
  Types.ParticipantNotesQuery
>((variables) => ['participant', variables, 'notes'], ParticipantNotes);

export const useParticipantNotesMinimalQuery = createGqlQuery<
  Types.ParticipantNotesMinimalQueryVariables,
  Types.ParticipantNotesMinimalQuery
>((variables) => ['participant', variables, 'notes', 'minimal'], ParticipantNotesMinimal);

export const useParticipantNoteQuery = createGqlQuery<
  Types.ParticipantNoteQueryVariables,
  Types.ParticipantNoteQuery
>((variables) => ['participant', variables, 'note'], ParticipantNote);

export const useParticipantNoteVersionsQuery = createGqlQuery<
  Types.ParticipantNoteVersionsQueryVariables,
  Types.ParticipantNoteVersionsQuery
>((variables) => ['participant', variables, 'note', 'versions'], ParticipantNoteVersions);

export const useParticipantNoteVersionQuery = createGqlQuery<
  Types.ParticipantNoteVersionQueryVariables,
  Types.ParticipantNoteVersionQuery
>((variables) => ['participant', variables, 'note', 'version'], ParticipantNoteVersion);

export const useParticipantFilesQuery = createGqlQuery<
  Types.ParticipantFilesQueryVariables,
  Types.ParticipantFilesQuery
>((variables) => ['participant', variables, 'files'], ParticipantFiles);

export const useParticipantFilesMinimalQuery = createGqlQuery<
  Types.ParticipantFilesMinimalQueryVariables,
  Types.ParticipantFilesMinimalQuery
>((variables) => ['participant', variables, 'files', 'minimal'], ParticipantFilesMinimal);

export const useParticipantDocumentsQuery = createGqlQuery<
  Types.ParticipantDocumentsQueryVariables,
  Types.ParticipantDocumentsQuery
>((variables) => ['participant', variables, 'documents'], ParticipantDocuments);

export const useParticipantDocumentsMinimalQuery = createGqlQuery<
  Types.ParticipantDocumentsMinimalQueryVariables,
  Types.ParticipantDocumentsMinimalQuery
>((variables) => ['participant', variables, 'documents', 'minimal'], ParticipantDocumentsMinimal);

export const useParticipantDocumentQuery = createGqlQuery<
  Types.ParticipantDocumentQueryVariables,
  Types.ParticipantDocumentQuery
>((variables) => ['participant', variables, 'document'], ParticipantDocument);

export const useParticipantDocumentVersionsQuery = createGqlQuery<
  Types.ParticipantDocumentVersionsQueryVariables,
  Types.ParticipantDocumentVersionsQuery
>((variables) => ['participant', variables, 'document', 'versions'], ParticipantDocumentVersions);

export const useParticipantDocumentVersionQuery = createGqlQuery<
  Types.ParticipantDocumentVersionQueryVariables,
  Types.ParticipantDocumentVersionQuery
>((variables) => ['participant', variables, 'document', 'version'], ParticipantDocumentVersion);

export const useParticipantAuditTrailEntriesQuery = createGqlQuery<
  Types.ParticipantAuditTrailEntriesQueryVariables,
  Types.ParticipantAuditTrailEntriesQuery
>((variables) => ['participant', variables, 'events'], ParticipantAuditTrailEntries);

export const useParticipantWidgetLayoutItemsQuery = createGqlQuery<
  Types.ParticipantWidgetLayoutItemsQueryVariables,
  Types.ParticipantWidgetLayoutItemsQuery
>((variables) => ['participant', variables, 'widgets'], ParticipantWidgetLayoutItems);

export const useParticipantNotesFilterQuery = (
  participantId: string,
  filter: ParticipantNotesFilter,
) => {
  const { organization } = useAppContext();

  const { data, ...resp } = useParticipantNotesQuery({
    organizationId: organization.id,
    participantId,
  });

  const notes = (data?.organization?.participant?.notes as Common.ParticipantNote[]) ?? [];

  const filteredByContent = notes.filter((note) => {
    const extensions = getParticipantNoteExtensions({});
    const htmlFromYdoc = ydocToHtml(note.value.data, extensions);

    // Remove all HTML tags and attributes before filtering
    const textContent = htmlFromYdoc.replace(/<[^>]*>/g, '');
    return textContent?.toLowerCase().includes(filter.contentFilter.toLowerCase());
  });

  const filteredByLabels = !P.isEmpty(filter.labelFilter ?? [])
    ? notes.filter((note) => {
        return note.labels.some((label) => filter.labelFilter?.includes(label.id));
      })
    : notes;

  const filteredNotes = P.intersect(filteredByContent, filteredByLabels);

  return { ...resp, participantNotes: filteredNotes };
};

export const useParticipantFilesAndDocumentsFilterQuery = (
  participantId: string,
  filter: ParticipantFilesAndDocumentsFilter,
) => {
  const { organization } = useAppContext();

  const filesQuery = useParticipantFilesQuery({
    organizationId: organization.id,
    participantId,
  });

  const documentsQuery = useParticipantDocumentsQuery({
    organizationId: organization.id,
    participantId,
  });

  const isLoading = filesQuery.isLoading || documentsQuery.isLoading;
  const error = filesQuery.error || documentsQuery.error;

  const files =
    (filesQuery.data?.organization?.participant?.files as Common.ParticipantFile[]) ?? [];
  const documents =
    (documentsQuery.data?.organization?.participant?.documents as Common.ParticipantDocument[]) ??
    [];
  const filesAndDocuments = P.orderBy([...files, ...documents], (f) => f.createdAt, 'desc');

  const filteredByName = filesAndDocuments.filter((document) => {
    return document.name.toLowerCase().includes(filter.nameFilter.toLowerCase());
  });
  const filteredByLabels = !P.isEmpty(filter.labelFilter ?? [])
    ? filesAndDocuments.filter((document) => {
        return document.labels.some((label) => filter.labelFilter?.includes(label.id));
      })
    : filesAndDocuments;
  const filtered = P.intersect(filteredByName, filteredByLabels);

  return { isLoading, error, filesAndDocuments: filtered };
};

export const useParticipantDocumentVersionsInfiniteQuery = (
  variables: Types.ParticipantDocumentVersionsQueryVariables,
  options?: InfiniteQueryOptions<Types.ParticipantDocumentVersionsQuery>,
) => {
  const fetcher = useParticipantDocumentVersionsQuery.getFetcher();

  const query = useInfiniteQuery(
    useParticipantDocumentVersionsQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            ...variables.request?.pagination,
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage?.organization?.participant?.document?.versions?.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );

  return query;
};

export const useParticipantNoteVersionsInfiniteQuery = (
  variables: Types.ParticipantNoteVersionsQueryVariables,
  options?: InfiniteQueryOptions<Types.ParticipantNoteVersionsQuery>,
) => {
  const fetcher = useParticipantNoteVersionsQuery.getFetcher();

  const query = useInfiniteQuery(
    useParticipantNoteVersionsQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            ...variables.request?.pagination,
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage?.organization?.participant?.note?.versions?.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );

  return query;
};
