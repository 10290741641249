import {
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Spacer,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import React from 'react';
import { HookedSwitch } from './HookedSwitch';

interface Props extends StackProps {
  name: string;
  label: string;
  helperText: string;
  labelSize?: 'md' | 'sm';
}

export const HookedMethodFormItem = (props: React.PropsWithChildren<Props>) => {
  const { name, label, labelSize, helperText, children, ...rest } = props;

  return (
    <FormControl
      as={Stack}
      w="full"
      spacing={0}
      borderColor="gray.200"
      borderWidth="1px"
      rounded="md"
      py={3}
      px={4}
      align="start"
      {...rest}
    >
      <HStack w="full">
        <FormLabel fontWeight="semibold" mb={0} color="gray.700" fontSize={labelSize}>
          {label}
        </FormLabel>
        <Spacer />
        <HookedSwitch name={`${name}.enabled`} />
      </HStack>
      {helperText && (
        <FormHelperText fontSize="sm" whiteSpace="pre-wrap" mt={0}>
          {helperText}
        </FormHelperText>
      )}
      {children}
    </FormControl>
  );
};
