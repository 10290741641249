import * as ReactQRCode from 'react-qr-code';
import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';

export interface QRCodeProps extends Omit<IconButtonProps, 'aria-label'> {
  value: string;
}

export const QRCode = (props: QRCodeProps) => {
  const { value, ...rest } = props;

  return (
    <IconButton
      as={NavLink}
      to={value}
      variant="outline"
      aria-label="QR code"
      p={2}
      icon={
        <ReactQRCode.default
          value={value}
          size={256}
          bgColor="transparent"
          style={{
            height: 'auto',
            maxWidth: '100%',
            width: '100%',
          }}
        />
      }
      {...rest}
    />
  );
};
