import { Box } from '@chakra-ui/react';
import { ParticipantFieldGroupTemplate, Template } from '@piccolohealth/pbs-common';
import React from 'react';
import { useImageUpload } from '../../components/tiptap/hooks/useImageUpload';
import {
  getParticipantDocumentTemplateExtensions,
  getTemplateVariables,
} from '../../components/tiptap/utils';
import { useParticipantFieldGroupTemplatesQuery } from '../../graphql/hooks/useParticipantFieldGroupTemplatesQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { DocumentTemplateEditor } from './DocumentTemplateEditor';
import { TemplateTitleBar } from './TemplateTitleBar';

interface Props {
  template: Template;
}

export const ParticipantDocumentTemplateEditor = (props: Props) => {
  const { template } = props;

  const { organization, config } = useAppContext();

  const query = useParticipantFieldGroupTemplatesQuery({
    organizationId: organization.id,
  });

  const variables = React.useMemo(() => {
    const fieldGroupTemplates =
      (query.data?.organization
        ?.participantFieldGroupTemplates as ParticipantFieldGroupTemplate[]) ?? [];

    return getTemplateVariables(fieldGroupTemplates);
  }, [query.data?.organization?.participantFieldGroupTemplates]);

  const onImageUpload = useImageUpload({
    imageUploadUrl: `${config.api.url}/organizations/${organization.id}/templates/${template.id}/images`,
  });

  const extensions = getParticipantDocumentTemplateExtensions({ variables, onImageUpload });

  return (
    <Box borderTopWidth="1px" borderColor="gray.200" h="full">
      <DocumentTemplateEditor
        documentId={`template/${template.id}`}
        extensions={extensions}
        title={(options) => <TemplateTitleBar template={template} {...options} />}
        isEditable
      />
    </Box>
  );
};
