import gql from 'graphql-tag';

export const UpdateParticipantQR = gql`
  mutation UpdateParticipantQR(
    $organizationId: ID!
    $participantId: ID!
    $qrId: ID!
    $request: UpdateParticipantQrRequest!
  ) {
    updateParticipantQr(
      organizationId: $organizationId
      participantId: $participantId
      qrId: $qrId
      request: $request
    ) {
      id
    }
  }
`;
