// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087

import React, { ComponentProps, ComponentType } from 'react';

export const typedMemo: <T extends ComponentType<any>>(
  c: T,
  propsAreEqual?: (
    prevProps: Readonly<ComponentProps<T>>,
    nextProps: Readonly<ComponentProps<T>>,
  ) => boolean,
) => T = React.memo;
