import * as Common from '@piccolohealth/pbs-common';
import { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { FormStack } from '../../../components/forms/FormStack';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../components/forms/HookedInput';
import { HookedLabelChooser } from '../../../components/forms/HookedLabelChooser';
import { HookedSelect } from '../../../components/forms/HookedSelect';
import { HookedSingleDatepicker } from '../../../components/forms/HookedSingleDatepicker';
import { restrictedPracticeTypeOptions } from './RestrictedPracticeTypeTag';

export const ParticipantRestrictedPracticeForm = () => {
  const typeOptions: SelectOption<Common.RestrictedPracticeType>[] = Object.values(
    restrictedPracticeTypeOptions,
  );

  return (
    <FormStack>
      <HookedFormItem name="type" label="Type" isRequired>
        <HookedSelect name="type" size="sm" options={typeOptions} optionVariant="tag" />
      </HookedFormItem>

      <HookedFormItem
        name="description"
        label="Description"
        helperText="How would you describe the restricted practice?"
      >
        <HookedInput name="description" placeholder="Enter a description.." />
      </HookedFormItem>

      <HookedFormItem
        name="identifiedAt"
        label="Date identified"
        helperText="When was the restricted practice identified?"
      >
        <HookedSingleDatepicker name="identifiedAt" />
      </HookedFormItem>

      <HookedFormItem
        name="startedAt"
        label="Date started"
        helperText="When did the restricted practice start being used in practice?"
      >
        <HookedSingleDatepicker name="startedAt" />
      </HookedFormItem>

      <HookedFormItem
        name="ceasedAt"
        label="Date ceased"
        helperText="When did the restricted practice stop being used in practice?"
      >
        <HookedSingleDatepicker name="ceasedAt" />
      </HookedFormItem>

      <HookedFormItem name="labelIds" label="Labels">
        <HookedLabelChooser name="labelIds" type={Common.LabelType.ParticipantRestrictedPractice} />
      </HookedFormItem>
    </FormStack>
  );
};
