import { ParticipantQrStatus } from '@piccolohealth/pbs-common';
import React from 'react';

const DEFAULT_NAME_FILTER: string | undefined = '';
const DEFAULT_STATUS_FILTER: ParticipantQrStatus[] = [ParticipantQrStatus.Active];
const DEFAULT_LABEL_FILTER: string[] = [];

export interface ParticipantQRsFilter {
  nameFilter: string;
  statusFilter: ParticipantQrStatus[];
  labelFilter: string[];
  activeFilterCount: number;
  onNameFilter: (name: string) => void;
  onStatusFilter: (status: ParticipantQrStatus[]) => void;
  onLabelFilter: (label: string[]) => void;
  reset: () => void;
}

export const useParticipantQRsFilter = (): ParticipantQRsFilter => {
  const [params, setParams] = React.useState({
    name: DEFAULT_NAME_FILTER,
    status: DEFAULT_STATUS_FILTER,
    label: DEFAULT_LABEL_FILTER,
  });

  const onNameFilter = (value: string) =>
    setParams((prev) => ({
      ...prev,
      name: value,
    }));

  const onStatusFilter = (value: ParticipantQrStatus[]) =>
    setParams((prev) => ({
      ...prev,
      status: value,
    }));

  const onLabelFilter = (value: string[]) =>
    setParams((prev) => ({
      ...prev,
      label: value,
    }));

  const reset = () =>
    setParams({
      name: DEFAULT_NAME_FILTER,
      status: DEFAULT_STATUS_FILTER,
      label: DEFAULT_LABEL_FILTER,
    });

  const activeFilterCount = [
    params.name.length > 0,
    params.status.length > 0,
    params.label.length > 0,
  ].filter(Boolean).length;

  return {
    nameFilter: params.name,
    statusFilter: params.status,
    labelFilter: params.label,
    activeFilterCount,
    onNameFilter,
    onStatusFilter,
    onLabelFilter,
    reset,
  };
};
