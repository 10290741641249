import { InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { FieldTemplateType } from '@piccolohealth/pbs-common';
import { LooseObject } from '@piccolohealth/util';
import React from 'react';
import { HookedInput } from './HookedInput';
import { HookedMultiSelect } from './HookedMultiSelect';
import { HookedSelect } from './HookedSelect';
import { HookedSingleDatepicker } from './HookedSingleDatepicker';
import { HookedTextArea } from './HookedTextArea';

interface Props {
  type: FieldTemplateType;
  name: string;
  attributes: LooseObject;
  isDisabled?: boolean;
}

export const HookedFieldControl = (props: Props) => {
  const { type, name, attributes, isDisabled } = props;

  switch (type) {
    case FieldTemplateType.Input:
      return (
        <InputGroup size="sm">
          {attributes.prefix && <InputLeftElement>{attributes.prefix}</InputLeftElement>}
          <HookedInput name={name} placeholder="Enter a value" isDisabled={isDisabled} />
          {attributes.suffix && <InputRightElement>{attributes.suffix}</InputRightElement>}
        </InputGroup>
      );

    case FieldTemplateType.Textarea:
      return (
        <HookedTextArea name={name} size="sm" placeholder="Enter a value" isDisabled={isDisabled} />
      );

    case FieldTemplateType.Select:
      return (
        <HookedSelect
          name={name}
          options={attributes.options}
          size="sm"
          placeholder="Choose a value"
          isDisabled={isDisabled}
        />
      );

    case FieldTemplateType.MultiSelect:
      return (
        <HookedMultiSelect
          name={name}
          options={attributes.options}
          size="sm"
          optionVariant="tag"
          placeholder="Choose one or more values"
          isDisabled={isDisabled}
        />
      );

    case FieldTemplateType.Datepicker:
      return (
        <HookedSingleDatepicker
          name={name}
          size="sm"
          placeholder="Choose a date"
          isDisabled={isDisabled}
        />
      );
  }
};
