import { Stack } from '@chakra-ui/react';
import { FILE_SIZE_LIMIT_32MB_BYTES } from '@piccolohealth/pbs-common';
import { FileUploadControl, useFileUpload } from '@piccolohealth/ui';
import { Editor } from '@tiptap/core';
import React from 'react';

interface Props {
  editor: Editor;
  onImageUpload?: (file: File) => Promise<string>;
  onExit: () => void;
}

export const ImageUploadPage = (props: Props) => {
  const { editor, onImageUpload, onExit } = props;

  const onAdd = React.useCallback(
    (imageUrl: string | null) => {
      if (imageUrl) {
        editor.chain().focus().setImage({ src: imageUrl }).run();
      }
      onExit();
    },
    [editor, onExit],
  );

  const sendFile = React.useCallback(
    async (file: File) => {
      if (!onImageUpload) {
        return;
      }

      const url = await onImageUpload(file);
      onAdd(url);
    },
    [onImageUpload, onAdd],
  );

  const { status, files, onFilesChange } = useFileUpload({
    sendFile,
    concurrency: 1,
    autoStart: true,
    maxFileSize: FILE_SIZE_LIMIT_32MB_BYTES,
  });

  return (
    <Stack w="sm" p={4}>
      <FileUploadControl
        multiple={false}
        files={files}
        status={status}
        onFilesChange={onFilesChange}
        accept="image/*, .heic"
      />
    </Stack>
  );
};
