import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { CreateParticipantRestrictedPracticeLabel } from '../mutations/createParticipantRestrictedPracticeLabel.mutation';
import {
  CreateParticipantRestrictedPracticeLabelMutation,
  CreateParticipantRestrictedPracticeLabelMutationVariables,
} from '../types';

export const useCreateParticipantRestrictedPracticeLabelMutation = (
  options?: MutationOptions<
    CreateParticipantRestrictedPracticeLabelMutation,
    CreateParticipantRestrictedPracticeLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantRestrictedPracticeLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
