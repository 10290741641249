import { Flex } from '@chakra-ui/react';
import { EditorContent } from '@tiptap/react';
import React from 'react';
import { useEditor } from '../../components/tiptap/hooks/useEditor';
import {
  convertYDocToJSONContent,
  deserializeYDoc,
  getParticipantNoteExtensions,
  postProcessContent,
} from '../../components/tiptap/utils';

interface Props {
  value: ReturnType<Buffer['toJSON']>;
}
const extensions = getParticipantNoteExtensions({});

export const ParticipantNoteEditorViewOnly = (props: Props) => {
  const jsonContent = React.useMemo(() => {
    const yDoc = deserializeYDoc(props.value.data ?? {});
    const jsonContent = convertYDocToJSONContent(yDoc ?? {});
    const processedJsonContent = postProcessContent(jsonContent, []);
    return processedJsonContent;
  }, [props.value.data]);

  const { editor } = useEditor(
    {
      extensions,
      isEditable: false,
      content: jsonContent,
    },
    [jsonContent],
  );

  if (!editor) {
    return null;
  }

  return <Flex as={EditorContent} editor={editor} />;
};
