import { useMergeRefs, Input, InputProps, forwardRef } from '@chakra-ui/react';
import React, { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';

interface Props extends InputProps {
  name: string;
  noNulls?: boolean;
}

export const HookedInput = forwardRef<Props, 'input'>((props, forwardedRef) => {
  const { name, noNulls = false, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const mergedRef = useMergeRefs(ref, forwardedRef);

  const onChange = React.useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (noNulls) {
        inputProps.onChange(event.target.value);
      } else {
        event.target.value === ''
          ? inputProps.onChange(null)
          : inputProps.onChange(event.target.value);
      }
    },
    [inputProps, noNulls],
  );

  return (
    <Input
      {...inputProps}
      onChange={onChange}
      value={inputProps.value}
      ref={mergedRef}
      size="sm"
      {...rest}
    />
  );
});

HookedInput.id = 'Input';
