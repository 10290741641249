import { Button, Card, CardBody, CardHeader, Heading, Icon, Stack, Text } from '@chakra-ui/react';
import { Participant, ParticipantBehaviour, ParticipantQr } from '@piccolohealth/pbs-common';
import { Empty, Spin } from '@piccolohealth/ui';
import { matchSorter, P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { useParticipantQrsQuery } from '../../graphql/hooks/useParticipantQuery';
import { usePermission } from '../../hooks/usePermission';
import { useParticipantQRsFilter } from './hooks/useParticipantQRsFilter';
import { ParticipantQRCreateModal } from './ParticipantQRCreateModal';
import { ParticipantQRFilterControls } from './ParticipantQRFilterControls';
import { ParticipantQRListItem } from './ParticipantQRListItem';

interface Props {
  participant: Participant;
  isDisabled?: boolean;
}

export const ParticipantQRCard = (props: Props) => {
  const { participant } = props;

  const createParticipantQRPermission = usePermission('create', 'participantQr');
  const isDisabled = props.isDisabled || !createParticipantQRPermission;
  const filter = useParticipantQRsFilter();

  const query = useParticipantQrsQuery({
    organizationId: participant.organizationId,
    participantId: participant.id,
  });

  const participantBehaviours = (query.data?.organization?.participant?.behaviours ??
    []) as ParticipantBehaviour[];

  const qrs = React.useMemo(() => {
    const results = (query.data?.organization?.participant?.qrs as ParticipantQr[]) ?? [];

    const nameFiltered = matchSorter(results, filter.nameFilter, {
      keys: ['name'],
    });

    const statusFiltered = !P.isEmpty(filter.statusFilter)
      ? nameFiltered.filter((v) => filter.statusFilter.includes(v.status))
      : nameFiltered;

    const labelFiltered = !P.isEmpty(filter.labelFilter)
      ? statusFiltered.filter((v) => v.labels?.some((l) => filter.labelFilter.includes(l.id)))
      : statusFiltered;

    return labelFiltered;
  }, [
    filter.labelFilter,
    filter.nameFilter,
    filter.statusFilter,
    query.data?.organization?.participant?.qrs,
  ]);

  const content = P.run(() => {
    if (query.isLoading) {
      return <Spin />;
    }

    if (P.isEmpty(qrs)) {
      return <Empty h="full" title="No QR codes found" p={0} />;
    }

    return (
      <Stack spacing={10}>
        {qrs.map((qr) => (
          <ParticipantQRListItem
            key={qr.id}
            qr={qr}
            participant={participant}
            participantBehaviours={participantBehaviours}
            isDisabled={isDisabled}
          />
        ))}
      </Stack>
    );
  });

  return (
    <Card h="full">
      <CardHeader>
        <Stack spacing={0}>
          <Heading size="md">QR codes</Heading>
          <Text fontSize="sm" color="secondary">
            {`Manage this participant's external team members`}
          </Text>
        </Stack>
      </CardHeader>
      <CardBody>
        <Stack spacing={6}>
          <ParticipantQRFilterControls filter={filter} />
          {content}
          <Button
            isDisabled={isDisabled}
            size="sm"
            leftIcon={<Icon as={FaPlus} />}
            onClick={() =>
              showModal(ParticipantQRCreateModal, {
                participantId: participant.id,
              })
            }
          >
            Add QR code
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};
