import { Button, Divider, Icon, Wrap, WrapItem } from '@chakra-ui/react';
import { Form } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { useOrganizationQuery } from '../../graphql/hooks/useOrganizationQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { FormCard } from './forms/FormCard';
import { FormCreateModal } from './forms/FormCreateModal';

export const FormSettings = () => {
  const { organization } = useAppContext();
  const { isLoading, data, error } = useOrganizationQuery({
    organizationId: organization.id,
  });

  const forms = data?.organization?.forms as Form[];

  const createFormPermission = usePermission('create', 'form');

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <FormStack maxW="lg">
      <FormSection
        heading="Forms"
        description="Your forms to share on social media, messaging apps or via email."
      >
        <Divider />

        <Wrap spacing={4} w="full">
          {forms.map((form) => (
            <WrapItem key={form.id} w="full">
              <FormCard form={form} />
            </WrapItem>
          ))}
        </Wrap>
      </FormSection>
      <Button
        isDisabled={!createFormPermission}
        w="fit-content"
        size="sm"
        colorScheme="purple"
        leftIcon={<Icon as={FaPlus} />}
        onClick={() => showModal(FormCreateModal)}
      >
        Add new form
      </Button>
    </FormStack>
  );
};
