import {
  forwardRef,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  useMergeRefs,
} from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends NumberInputProps {
  name: string;
}

export const HookedNumberInput = forwardRef<Props, 'input'>((props, forwardedRef) => {
  const { name, defaultValue, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name, defaultValue });

  const mergedRef = useMergeRefs(ref, forwardedRef);

  const onChange = React.useCallback(
    (_valueAsString: string, valueAsNumber: number) => {
      inputProps.onChange(valueAsNumber);
    },
    [inputProps],
  );

  return (
    <NumberInput
      colorScheme="purple"
      {...inputProps}
      onChange={onChange}
      value={inputProps.value}
      ref={mergedRef}
      size="sm"
      bg="white"
      w="full"
      keepWithinRange
      clampValueOnBlur
      {...rest}
    >
      <NumberInputField rounded="md" />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
});
