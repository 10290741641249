import { Box, Button, ButtonProps, Stack, Text } from '@chakra-ui/react';
import { Participant, renderParticipantName } from '@piccolohealth/pbs-common';
import * as htmlToImage from 'html-to-image';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantQRCode } from './ParticipantQRCode';

interface Props extends ButtonProps {
  qrId: string;
  participant: Participant;
}

export const ParticipantQRCopyImageButton = (props: Props) => {
  const { qrId, participant, children, ...rest } = props;

  const { organization, successToast, errorToast } = useAppContext();
  const ref = React.useRef<HTMLDivElement>(null);

  const handleCopy = async () => {
    if (ref.current) {
      const dataUrl = await htmlToImage.toPng(ref.current, {
        pixelRatio: 5,
      });
      const blob = await (await fetch(dataUrl)).blob();
      try {
        const item = new ClipboardItem({ 'image/png': blob });
        await navigator.clipboard.write([item]);
        successToast('QR code image copied to clipboard');
      } catch (error) {
        errorToast('Failed to copy QR code image to clipboard. Not supported on Safari or Firefox');
      }
    }
  };

  return (
    <>
      <Box pos="absolute" zIndex={-1}>
        <Stack ref={ref} align="center" p={2}>
          <ParticipantQRCode qrId={qrId} />
          <Stack spacing={0} textAlign="center">
            <Text fontSize="md" fontWeight="bold">
              {renderParticipantName(participant)}
            </Text>
            <Text fontSize="xs" fontWeight="bold">
              {organization.name}
            </Text>
          </Stack>
        </Stack>
      </Box>
      <Button size="xs" w="fit-content" onClick={handleCopy} {...rest}>
        {children}
      </Button>
    </>
  );
};
