import gql from 'graphql-tag';

export const CreateParticipantBehaviourRecording = gql`
  mutation CreateParticipantBehaviourRecording(
    $organizationId: ID!
    $participantId: ID!
    $participantBehaviourId: ID!
    $request: CreateParticipantBehaviourRecordingRequest!
  ) {
    createParticipantBehaviourRecording(
      organizationId: $organizationId
      participantId: $participantId
      participantBehaviourId: $participantBehaviourId
      request: $request
    ) {
      __typename
    }
  }
`;
